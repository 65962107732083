import React, { FC } from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { size2 } from 'styles/variables';
import img from '../../resources/offer/bonhomme_ampoule.svg';

interface Props {
  isMobile: boolean
}

const paragraph_1 = `Il s’agit de la célèbre méthode crée par Claude Champagne et Adrien Paillette dans les années 90 au Québec.`;
const paragraph_2 = `Elle s’appuie sur l’intelligence collective et l’agilité d’un groupe de pairs pour apporter des solutions créatives et inattendues à tous types de défis professionnels. Le codéveloppement a depuis fait ses preuves à travers le monde et en France notamment.`;

const CodevComponent: FC<Props> = ( { isMobile } ) => {
  return (
    <CodevComponentStyled>
      <h2 className="offreTitle">QU’EST-CE QUE LE CODÉVELOPPEMENT PROFESSIONNEL ?</h2>
      <div className="offerContainer">

        {
          isMobile ? (
            <Flex column alignCenter className="codevMobile">
              <p>{ paragraph_1 }</p>
              <img src={ img } width={ 100 } alt="Ampoule and bonhomme"/>
              <p>{ paragraph_2 }</p>
            </Flex>
          ) : (
            <Flex className="codevDesktop">
              <div>
                <img src={ img } width={ 100 } alt="Ampoule and bonhomme"/>
              </div>
              <div>
                <p>
                  { paragraph_1 } <br/>
                  { paragraph_2 }
                </p>
              </div>
            </Flex>
          )
        }
      </div>
    </CodevComponentStyled>
  );
};

const CodevComponentStyled = styled.div`
  text-align : center;

  .codevDesktop {
    img {
      margin-right : ${size2};
    }
  }

  .codevMobile {
    img {
      margin-bottom : ${ size2 };
    }
  }
`;

export default CodevComponent;

