import React, { useRef, useState, Fragment } from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import arrow from '../../resources/svg/petite_fleche_degrade.svg';
import arrowStart from '../../resources/svg/pointe_fleche_noire.svg';
import aim from '../../resources/svg/cible.svg';
import { Waypoint } from 'react-waypoint';

const ourAimBulletPoint = [
  <p>Managers <br/> de directions métier <br/>
     (Project, Vente etc...)</p>,
  <p>Directions transverses :
    <br/>DRH, Direction de l’Innovation, <br/>de la Transformation,<br/> du Changement
  </p>,
  <p> Directions générales et <br/> Comités Exécutifs </p>
];

const Item = ( { text } ) =>
  <Flex className="item" alignCenter>
    <FlexItem className="arrow">
      <img width={ 45 } src={ arrow } alt="fleche"/>
    </FlexItem>
    <FlexItem>
      { text }
    </FlexItem>
  </Flex>;

const OurAimComponentDesktop = () => {
  const re = useRef();
  const [ visible, setVisible ] = useState( false );

  return (
    <OurAimComponentDesktopStyled>
      <Waypoint onEnter={ () => setVisible( true ) }>
        <Flex justifyAround>
          <FlexItem>
            {
              ourAimBulletPoint.map(
                ( el, index ) => <Item key={ index } text={ el }/> )
            }
          </FlexItem>
          <FlexItem ref={ re } className="cibleContainer">
            {
              visible ?
              <Fragment>
                <div className="firstArrow cibleArrow"/>
                <div className="secondArrow cibleArrow"/>
                <div className="thirdArrow cibleArrow"/>
                <div className="arrowStart">
                  <img src={ arrowStart } alt="pointe d'une fleche"/></div>
              </Fragment>
                      : null
            }
            <img className="cible" src={ aim } alt="cible"/>
          </FlexItem>
        </Flex>
      </Waypoint>
    </OurAimComponentDesktopStyled>
  );
};

const OurAimComponentDesktopStyled = styled.div`

   @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

  p {
    font-weight: bold;
  }

  .cibleContainer {
    position: relative;
    
    > div {
      opacity: 0;
      animation: fadein 2s both;
      z-index: 200;
      position: absolute;
    }
    
    .cibleArrow {
      background: black;
      height: 2px;
    }

    .firstArrow {
      animation-delay: 200ms;
      opacity: 0;
      width: 374px;
      top: 104px;
      left: -211px;
      transform: rotate(15deg);
    }
    
    
    .secondArrow {
      width: 289px;
      top: 152px;
      left: -134px;
    }
    
    .thirdArrow {
      width: 374px;
      animation-delay: 400ms;
      top: 200px;
      left: -211px;
      transform: rotate(-15deg);
    }
    
    .arrowStart {
      z-index: 1000;
      width: 20px;
      top: 139px;
      right: 134px;
    }
  }

  .cible {
    width: 300px;
    position: relative;
  }

  .item {
    margin-bottom: 20px;

    img {
      margin-right: 24px;
    }

    p {
      margin: 0;
    }
  }

`;

export default OurAimComponentDesktop;
