import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { ClientLogoCarousel } from './our-client.component.desktop';
import { ourClientDataDesktop } from './our-client.data.desktop';

const OurClientComponentMobile = () => {

  const [ state, setState ] = useState( [ ...ourClientDataDesktop ] );
  const [ , setCurrentIndex ] = useState( 1 );

  const switchColor = ( index ) => {
    const tmpState = state.map( ( el ) => ( { ...el, state : false } ) );
    tmpState[ index ].state = !tmpState[ index ].state;
    setState( tmpState );
    setCurrentIndex( index );
  };

  return (
    <OurClientComponentMobileStyled>
      <ClientLogoCarousel ourClientData={ state } switchColor={ switchColor } slidesToShow={ 1 }/>
    </OurClientComponentMobileStyled>
  );
};

const OurClientComponentMobileStyled = styled( Container )`
  .mobileSlider {
    img {
      width: 100%;
    }

    .animateItem {
      width: 150px;

    }

    .little {
      width: 100px
    }

    .blackIcon {
      opacity: 0.7;
    }

  }
`;

export default OurClientComponentMobile;
