//
//
//

// color
export const darkColor = '#485156';
export const darkColorOnBackground = '#303537';
export const mainColor = '#37E6B8';
export const mainColorRgb = '132,229,176';
export const myMain = 'rgba(78, 211, 139)';

// container size
export const maxContainerSize = '1000px';

export const firstGradient = `linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(${ mainColorRgb },1) 75%)`;
export const mainGradient = `linear-gradient(90deg, rgba(122,181,189,1) 0%, rgba(${ mainColorRgb },1) 65%);`;



export const desktopSize = '992px';
export const smallScreenSize = '576px';
export const bigDesktopSize = '1200px';
export const textMobileVerticalPadding = '0 24px';

// export const isDesktop = useMediaQuery({query: desktopSize})

// setup
// Scale system
export const size0 = '4px';
export const size1 = '8px';
export const size2 = '16px';
export const size3 = '24px';
export const size4 = '32px';
export const size5 = '40px';
export const size6 = '48px';
export const size7 = '56px';
export const size8 = '64px';

// Color system

// Font system
export const font0 = '10px';
export const font1 = '14px';
export const font2 = '16px';
export const font3 = '18px';
export const font4 = '20px';
export const font5 = '24px';
export const font6 = '28px';
export const font7 = '32px';
export const font8 = '36px';
export const font9 = '40px';
export const font10 = '44px';
export const font11 = '48px';
export const font12 = '72px';

export const rem0 = '1rem';
export const rem1 = '1.4rem';
export const rem2 = '1.6rem';
export const rem3 = '1.8rem';
export const rem4 = '2rem';
export const rem5 = '2.4rem';
export const rem6 = '2.8rem';
export const rem7 = '3.2rem';
export const rem8 = '3.6rem';
export const rem9 = '4rem';
export const rem10 = '4.8rem';
export const rem11 = '5.6rem';
export const rem12 = '7.2rem';


// offre
export const offerGradient = `linear-gradient(90deg, rgb(244, 207, 63) 0%, rgb(255,136,97) 100%)`;
export const offerColor = '#ff8861';
