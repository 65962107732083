import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import arrow from '../../resources/svg/petite_fleche_degrade.svg';
import { mainGradient } from '../../styles/variables';
import { ourMissionIcon } from './our-mission.data';

const ourMissionText = [
  {
    bold : 'Composé d’une équipe de coachs et formateurs expérimentés en management',
    text : 'Prairial travaille avec des organisations de tous types et met à leur dis position les techniques les plus innovantes en terme de management issus de l’environnement start up à impact.'
  },
  {
    bold : 'Prairial a pour objectif d’accompagner les organisations dans leur transformation managériale',
    text : 'pour les aider à retrouver un fonctionnement aligné avec les attentes du marché, et retrouver performance, sens et innovation.'
  },
  {
    bold : 'Notre ambition : Prairial a pour ambition de permettre à ses clients de retrouver',
    text : 'innovation structurelle, performance, fidélisation des collaborateurs et des clients.'
  }
];

const OurMissionComponentDesktop = () => {

  return (
    <OurMissionComponentDesktopStyled>
      <Flex justifyBetween>
        {
          ourMissionIcon.map( ( el, i ) => (
              <FlexItem className="item-container" key={ i }>
                <Flex className="item" justifyAround alignCenter column>
                  <div>
                    <p style={ {
                      marginBottom : el.margin,
                      textAlign    : 'center'
                    } }>{ el.title }</p>

                    <div>
                      <img style={ { transform : `rotate(${ el.deg }deg)` } }
                           width={ 45 }
                           src={ arrow } alt="arrow"/>
                    </div>
                  </div>
                </Flex>
              </FlexItem>
            )
          )
        }
      </Flex>

      <div style={ { marginTop : '24px' } } className="myContainer">
        {
          ourMissionText.map( ( { bold, text }, index ) => (
            <Flex key={ index } justifyCenter className="text">
              <p>
                <span> { bold }</span> { ' ' }
                { text }
              </p>
            </Flex> ) )
        }

      </div>
    </OurMissionComponentDesktopStyled>
  );

};

const OurMissionComponentDesktopStyled = styled.div`
  .item-container {
    height: 200px;
    width: 250px;
    padding: 3px;
    background: ${ mainGradient };

    .item {
      background: white;
      height: 100%;
    }

    img {
      display: block;
      margin: auto;
    }
  }

  .text {
    text-align: center;
    font-weight: 800;

  }
`;

export default OurMissionComponentDesktop;
