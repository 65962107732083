import styled from 'styled-components';
import { size6 } from './variables';

export const SectionStyle = styled.section`
  padding: ${size6} 0;
`;

export const ContainerStyled = styled.div`
  max-width: 1000px;
  margin: 0 20px;
`;
