import ariane from '../../resources/people/ariane.jpg';
import camille from '../../resources/people/camille.jpg';
import emeric from '../../resources/people/emeric.jpg';

export const teamData = [
  {
    skill   : 'Psychologue du travail et psychosociologue',
    name    : 'Ariane\nLe Jeune',
    img     : ariane,
    linkUrl : 'https://www.linkedin.com/in/ariane-le-jeune/',
    text    : [
      'Elle conçoit et anime des formations-actions sur le management, l’écoute, la qualité de vie au travail pour des entreprises et collectivités territoriales.',
      'Elle accompagne les équipes dans des phases délicates ou de transition et anime des groupes de parole pour des équipes en développement professionnel.',
      'Ariane est riche d\'une expérience de plus de 12 ans dans les entreprises privées dans le conseil en SIRH et formation.'
    ]
  }, {
    skill   : 'Experte en leadership et management',
    name    : 'Camille\nRollandin',
    img     : camille,
    linkUrl : 'https://www.linkedin.com/in/camille-rollandin-coach-pro/',
    text    : [
      'Coach d’équipes et d’organisations, elle accompagne les équipes dirigeantes sur les thématiques de leadership collectif  et de coopération inter-équipes. Elle conseille les entreprises dans la croissance de leur activité par la structuration de leurs pratiques managériales. ',
      'Elle a une expérience de 25 ans dans le secteur bancaire, dans des contextes internationaux, des environnements exigeants et en évolution rapide dans des postes d\'accompagnement humain, de conduite du changement et d\'optimisation des processus et mise en place de démarches d’Amélioration Continue.'
    ]
  }, {
    skill   : 'Gestalt thérapeute & coach organisationnel',
    name    : 'Emeric\nDeffrenne',
    img     : emeric,
    linkUrl : 'https://www.linkedin.com/in/smartnsimple',
    text    : [
      'Il accompagne au quotidien les comités de direction et les équipes commerciales dans le développement de leur performance.',
      'Au cours de ses 13 ans d’expérience en vente, management et direction de business-unit. Il est aujourd’hui executive coach, formateur certifié en ennéagramme et diplômé de l’école de Gestalt Thérapie.',
      'Il apporte sa double approche de Gestalt thérapeute et de coach de dirigeants aux organisations avec une attention particulière pour le développement des personnes et l\'influence des relations humaines sur la performance de l\'entreprise.'
    ]
  }
];


