import React from 'react';
import Slider, { Settings } from 'react-slick';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import jenny from '../../resources/images/jenny_detouree_chemiseblanche.webp';
import { startUpCarousel } from '../../setup';
import { SectionTitle } from '../../styles/font';
import { darkColorOnBackground, mainGradient } from '../../styles/variables';
import TeamDetailButtonComponent from './team-detail-button.component';
import { teamData } from './team-other.component';

const PeopleComponent = ( { skill, name, img, linkUrl } ) =>
  <PeopleComponentStyled>
    <div>
      <p className="people__name">{ name }</p>
      <p>{ skill }</p>
      <img width={ 250 } src={ img } alt="personne"/>
      <TeamDetailButtonComponent linkUrl={ linkUrl }/>
    </div>
  </PeopleComponentStyled>;

const PeopleComponentStyled = styled.div`

  background: ${ mainGradient };
  padding: 4px;
  width: 90%;
  max-width: 400px;
  margin: auto;
  
  & > div {
    background: white;
    padding: 16px;
  }
  
  p {
    font-weight: 700;
    text-align: center;
    margin: 0;
    font-size: 16px;
  }
  
  .people__name {
    font-size: 20px;
  }
  
  img {
    display: block;
    margin: 20px auto;
  }
  
`;
const settings: Settings = {
  centerMode     : true,
  infinite       : true,
  speed          : 600,
  autoplay       : startUpCarousel,
  autoplaySpeed  : 10000,
  slidesToShow   : 1,
  slidesToScroll : 1,
  arrows         : false
};

const TeamOther = () =>
  <div style={ { paddingBottom : '40px' } }>

    <SectionTitle justifyCenter>
      <h1>l'Equipe</h1>
    </SectionTitle>

    <Slider { ...settings }>
      { teamData.map( ( el, i ) => <PeopleComponent { ...el } key={ i }/> ) }
    </Slider>
  </div>;

const TeamComponentMobile = () =>
  <TeamComponentMobileStyled>

    <TeamOther/>

    <div className="jennyContainer MyContainer">
      <SectionTitle justifyCenter><h1>fondatrice & dirigeante</h1></SectionTitle>

      <Flex alignCenter justifyBetween column>
        <p className="jenny-name">Jenny Augias
        </p>
        <p className="jenny-post">
          Consultante-coach, <br/>formatrice en dynamiques managériales
        </p>

        <img width="200px" src={ jenny } alt="jenny" className="center-img jenny"
             style={ { marginBottom : '16px' } }/>

        <p
          className="p-hyphen">Executive coach spécia&shy;lisée dans l’intelligence re&shy;lationnelle et le manage&shy;ment
                               collaboratif, elle crée des approches inno&shy;vantes et humanistes pour accompagner
                               les managers et entre&shy;preneurs.</p>

        <TeamDetailButtonComponent
          linkUrl="https://www.linkedin.com/in/jennyaugias"/>
      </Flex>
    </div>

  </TeamComponentMobileStyled>;

const TeamComponentMobileStyled = styled.div`
  p{
    font-weight: bold;
    font-size: 16px;
  }
  .jennyContainer {
    background: ${ mainGradient };
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .jenny-name   {
    font-size: 24px;
  }
  .jenny-post {
    text-align: center;
    font-size: 18px;
  }
  .p-hyphen {
    hyphens: auto;
    text-align: center
  }

  .bold {

    font-weight: 700;
    text-align: center;
    line-height: 1;
    font-size: 16px;
  }

  .jenny {
    width: 250px;
    border-bottom: ${ darkColorOnBackground } solid 2px;
  }

`;

export default TeamComponentMobile;
