import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import { font2, font3 } from '../../styles/variables';
import { NumberPart } from './our-skills.component.desktop';
import { SkillsSlider } from './our-skills.slider.component';

const Addition = () =>
  <Flex alignCenter justifyAround>
    <FlexItem basis="35%">
      <p className="no-pm">Formation <br/> Continuelle</p>
      <p className="plus">+</p>
      <p className="no-pm">Veille <br/> Continuelle</p>
      <p className="plus">+</p>
      <p className="no-pm">Certification <br/> Continuelle</p>
    </FlexItem>
    <FlexItem basis="5%" style={ { height : '40px' } }>
      <p className="equal"> = </p>
    </FlexItem>
    <FlexItem basis="40%">
      <p className="bold">
        Maîtrise des Dernières Innovations
      </p>
    </FlexItem>
  </Flex>;

const OurSkillsComponentMobile = () =>
  <OurSkillsComponentMobileStyled>

    <NumberPart/>

    <Addition/>

    <h4 className="subTitle">Une équipe d’experts indépendants intervenant en France et à l’étranger</h4>
    <p className="text-intro">
      Chaque membre de l'équipe prairial a un double profil avec une expérience significative en entreprise et des expertises complémentaires comme :</p>

    <div className="skill-slider">
      <SkillsSlider nbSlide={1}/>
    </div>

  </OurSkillsComponentMobileStyled>;

const OurSkillsComponentMobileStyled = styled( Container )`
  text-align: center;
  p {
  font-size: ${ font2 };
  font-weight: bold;
  }
  
    .subTitle {
    margin-top: 24px;
    font-family: "Bellota", cursive;
    font-weight: bold;
    font-size: ${ font3 };
  }
 
  .skills {
   margin-top: 24px;
  }

  .plus {
    color: white;
    font-size: 20px;
    height: 20px;
    line-height: 10px;
    margin: 4px;
  }

  .equal {
    color: white;
    font-size: 60px;
    line-height: 30px;
  }

  .white-sign {
    font-size: 20px;
    color: white;
    text-align: center;
    line-height: 18px;
    margin: 0
  }

`;

export default OurSkillsComponentMobile;
