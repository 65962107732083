import styled from 'styled-components';
import { font7, mainColor, mainGradient, maxContainerSize, size3 } from './variables';

const AppStyled = styled.div`
  .titleMarginDesktop {
    @media (min-width: 800px) {
    margin-bottom: 34px;
    }
  }

  html {
    font-size : 62.5%
  }

  * {
    outline : none !important;
  }

  a {
    cursor          : pointer !important;
    text-decoration : none !important;
    color           : inherit !important;
  }

  p {
    outline : none !important;
    //font-weight : 800;
    //font-size   : 16px;
  }

  .myContainer {
    max-width     : ${ maxContainerSize };
    margin        : auto;
    padding-left  : ${ size3 };
    padding-right : ${ size3 };
  }

  .underlineBig {
    position    : relative;
    white-space : nowrap;
    font-weight : inherit;
    font-family : inherit;

    &:after {
      position   : absolute;
      content    : "";
      z-index    : -2;
      height     : 25%;
      left       : 0;
      right      : 0;
      width      : 100%;
      bottom     : 8%;
      opacity    : .85;
      background : ${ mainColor };
    }
  }

  .bigTitle {
    font-weight : bold;
    font-family : 'Bellota', cursive;
    text-align  : center;
    font-size   : ${ font7 };
    color       : ${ mainGradient };
    max-width: 800px;
    margin: auto;
  }

`;

export default AppStyled;
