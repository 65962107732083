import React from 'react';
import styled from 'styled-components';
import { FlexItem } from 'styled-flex-component';
import bottomArrow from '../../resources/svg/picto_fleche_equipe.svg';
import plus from '../../resources/svg/picto_plus_equipe.svg';
import { mainGradient } from '../../styles/variables';

const TeamItemComponentDesktop = ( { name, skill, img } ) =>
  <TeamItemComponentDesktopStyled basis="28%">
    <div className="element">
      <p className="name"> { name } </p>
      <div className="bottomArrow">
        <img src={ bottomArrow } alt="fleche vers le bas"/>
      </div>
      <p className="skill">{ skill }</p>
      <img src={ img } alt="profile"/>

      <a className="buttonPlus" href="/team">
        <img src={ plus } alt="button plus"/>
      </a>

    </div>
  </TeamItemComponentDesktopStyled>;

const TeamItemComponentDesktopStyled = styled( FlexItem )`

  background: ${ mainGradient };
  padding: 4px;
  .element {
  
  img {
    width: 100%;
  }
  text-align: center;
  padding: 16px 0;
  font-weight: bold;
  background: white;

  .name {
    font-size: 24px;
    margin: 0;
  }

  .bottomArrow {
    width: 20%;
    display: block;
    margin: auto;
  }

  .buttonPlus {
    padding-top: 16px;
    display: block;

    img {
      display: block;
      margin: auto;
      width: 12%;
    }
  }
  }
`;

export default TeamItemComponentDesktop;
