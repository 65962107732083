import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import Flex from "styled-flex-component";

const dataSkills = [
	"le Design Thinking", "la Communication Non Violente", "l’Appreciative Inquiry",
	"Le Codéveloppement", "l’Agilité", "les Outils de Diagnostic Managérial", "le Coaching Organisationnel", "La Psychologie Positive"
];

export const SkillsSlider = ( { nbSlide = 3 } ) => {

	const settings = {
		infinite      : true,
		speed         : 1000,
		autoplay      : true,
		autoplaySpeed : 3000,
		slidesToShow  : nbSlide,
		slidesToScroll: 1,
		arrows        : false,
	};

	return (
			<div>
				<HistorySliderStyled>
					<Slider { ...settings }>
						{ dataSkills.map( ( e, i ) =>
								<div style={ { height: "90px" } } key={ i }>
									<Flex key={ i } style={ { height: "90px" } } alignCenter
												justifyCenter full>
										<p className="text-white big no-margin"
											 style={ {
												 textTransform: "capitalize",
												 textAlign    : "center"
											 } }>
											{ e }
										</p>
									</Flex>
								</div>
						) }
					</Slider>
				</HistorySliderStyled>
			</div> );
};


const HistorySliderStyled = styled.div`
	margin-bottom: 16px;
  .white-text {
    text-align: center;
    color: white;
    font-weight: 600;
    margin: 0;
    line-height: 1;
  }
  
  .big {
  	margin-top: 14px;
  	font-size: 28px;
  }
  
  .no-margin {
  	margin: 0 !important;
  }
  
`;

