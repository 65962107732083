import React, { FC } from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import arrow from '../../../resources/svg/petite_fleche_degrade.svg';

interface Props {
  text: string[]
}

const ClientDesktopCarouselDescriptionElement: FC<Props> = ( { text = [] } ) => {

  const img = [ arrow, arrow, arrow ];

  return (
    <ClientDesktopCarouselDescriptionElementStyled>

      <p className="clientTitle">{ text[ 0 ] }</p>

      <Flex justifyAround>
        {
          img.map(
            ( el, i ) => <FlexItem key={ i } basis="30%">
              <Flex column justifyCenter alignCenter>
                <img src={ el } alt=""/>
                <p>{ text[ i + 1 ] }</p>
              </Flex>
            </FlexItem>
          )
        }
      </Flex>

    </ClientDesktopCarouselDescriptionElementStyled>
  );
};

const ClientDesktopCarouselDescriptionElementStyled = styled.div`
  height: 320px;
  p {
    font-weight: bold;
  }
  img {
    width         : 35px;
    transform     : rotate(90deg);
    margin-bottom : 8px;
  }

  .clientTitle {
    text-align  : center;
    font-weight : bold;
    font-size   : 24px;
  }
`;

export default ClientDesktopCarouselDescriptionElement;

