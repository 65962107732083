import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import arrow from '../../resources/svg/petite_fleche_degrade.svg';
import { font2, font3, textMobileVerticalPadding } from '../../styles/variables';
import { ourMissionIcon, ourMissionText } from './our-mission.data';

const OurMissionComponentMobile = () =>
  <OurMissionComponentMobileStyled>
    {
      ourMissionIcon.map( ( el, i ) =>
        <Flex key={ i } style={ { marginTop : '24px' } } column>

          <FlexItem basis="30%">
            <Flex contentstart alignCenter column>
              <p className="ourMission__title" style={ {
                marginBottom : el.margin,
                textAlign    : 'center'
              } }>{ el.title }</p>
              <img style={ {
                transform    : `rotate(${ el.deg }deg)`,
                marginBottom : el.marginBottom ? el.marginBottom : undefined
              } }
                   width={ 45 }
                   src={ arrow } alt="arrow"/>
            </Flex>
          </FlexItem>

          <FlexItem width="30%" className="text-padding">
            <div>{ ourMissionText[ i ] } </div>
          </FlexItem>
        </Flex> )
    }
  </OurMissionComponentMobileStyled>;

const OurMissionComponentMobileStyled = styled.div`

  .ourMission__title {
    font-size: ${ font3 };
  }
 
 .text-padding {
  padding: ${ textMobileVerticalPadding }
 } 

  img {
    margin-bottom: 16px; 
  }
  p{
  font-weight: bolder;
  font-size: ${ font2 };
  }

`;

export default OurMissionComponentMobile;
