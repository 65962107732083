import React from 'react';
import { SectionTitle } from '../../styles/font';
import { SectionStyle } from '../../styles/structure';
import { desktopSize, mainGradient } from '../../styles/variables';
import { useMediaQuery } from 'react-responsive';
import OurSkillsComponentDesktop from './our-skills.component.desktop';
import OurSkillsComponentMobile from './our-skills.component.mobile';
import styled from 'styled-components';
import pattern from '../../resources/svg/motif_fleches_loop.svg';

// const data = {
//   intro : 'Prairial est réseau d’experts indépendants intervenant\n en
// France & à l’étranger.' };

const OurSkillsContainer = () => {
  const isDesktopOrLaptop = useMediaQuery( {
    minWidth: 900
  } );

  return (
    <OurSkillsContainerStyled id="ourSkills">
      <div className="pattern">
        <SectionStyle>
          <SectionTitle justifyCenter><h1 className="titleMarginDesktop">Nos différenciants</h1></SectionTitle>
          { isDesktopOrLaptop ?
            <OurSkillsComponentDesktop/> :
            <OurSkillsComponentMobile/>
          }
        </SectionStyle>
      </div>
    </OurSkillsContainerStyled>
  );
};

const OurSkillsContainerStyled = styled.div`
    background: ${ mainGradient };

    .pattern {
      background: url(${ pattern }); 
      
      @media (min-width: ${ desktopSize }) {
      background-size: 50%;
      }
    }
`;

export default OurSkillsContainer;
