import React from 'react';
import simone from '../../resources/clients/hopitalsimoneveil.png';
import talan from '../../resources/clients/Logo Talan.png';
import tales from '../../resources/clients/Logo Thales.png';
import noisy from '../../resources/clients/mairie-de-noisy-le-grand-collectivite-locale-noisy-le-grand.png';
import scpo from '../../resources/clients/ScPo.png';
import sopra from '../../resources/clients/Sopra-Steria-e1495534571983.png';

export const ourClientDataDesktop = [
  {
    imgDefault : talan, state : true, text : [
      'Talan Labs (Groupe Talan)',
      'Accompagnement des managers et du board pour la transformation d’une entité en interne',
      'Conception et animation de séminaires, sessions de formation de formateur et groupes de codéveloppement',
      '1 séminaire de direction, 2 sessions de formation et 4 journées manager avec ateliers de cocréation et séances de codéveloppement pour 20 managers de proximité, 98% de satisfaction'
    ]
  },
  {
    imgDefault : tales, state : false, text : [
      'Thales Direction de l’Accompagnement du changement',
      'Animation d’ateliers collaboratifs pour accompagner les changements',
      'Formation  et ateliers d’accompagnement du changement dans le cadre du programme ERP France',
      '8 sessions de 2 jours, 80 experts métiers'
    ]
  },
  {
    imgDefault : noisy,
    state      : false,
    logoSize   : 150,
    text       : [
      'MAIRIE DE NOISY-LE-GRAND, Direction des Services Généraux',
      'Accompagnement du top management pour faciliter les changements liés à la mise en place de l’évaluation professionnelle.',
      'Conception et animation d’un séminaire de mobilisation des cadres et d’ateliers de cocréation des objectifs stratégiques des services de la ville.',
      '60 personnes accompagnées, 98% de satisfaction'
    ]
  },
  {
    imgDefault : scpo, state : false, text : [
      'Sciences Po Incubateur des politiques publiques Innovation et Entrepreneuriat',
      <>Coaching des équipes d'étudiants entrepreneurs dans le cadre du programme <a
        href="https://matrice.io">Matrice</a></>,
      'Un laboratoire de projets de société pour des partenaires publics et privés porteurs d\'enjeux numériques.',
      <>10 mois 40 étudiants sciences po et école 42, 4 solutions numériques en production pour les partenaires.</>

    ]
  },
  {
    imgDefault : sopra, state : false, text : [
      'Institut de formation Steria',
      'Parcours de développement des managers',
      'Conception et animation d’un parcours évolutif de formation avec codéveloppement',
      <>30 sessions, 3 modules de 3 + 2 + 2 jours, 300 managers</>
    ]
  },
  {
    imgDefault : simone, state : false, text : [
      'Hôpital Simone Veil',
      'Améliorer l’accueil de l’hôpital',
      'Conception et animation d’ateliers de co-construction avec la méthode design-thinking',
      '3 atelier de 4h, 80 participants (salariés, usagers)'
    ]
  }
];
