import React, { FC } from 'react';
import styled from 'styled-components';
import { font3, font4, font7, mainColor } from '../styles/variables';

interface Props {

}

const LegalPage: FC<Props> = () => {
  return (
    <LegalPageStyled className="myContainer">

      <h1>MENTIONS LEGALES ET POLITIQUE DE CONFIDENTIALITE</h1>

      <h1 className="titleName">Prairial</h1>

      <div className="address">
        <p>
          SASU capital de 15 000 € depuis 2012 <br/>
          Siège social : 361 rue Lecourbe 75015 Paris <br/>
          Tél : 06.47.81.38.73
        </p>
        <p>
          RCS PARIS 432 106 441 <br/>
          N° SIRET 752 389 098 0019 <br/>
          Activité de conseil et de formation professionnelle des adultes <br/>
          enregistrée sous le n° 11 75 49634 75 auprès du préfet de région IDF, référencé au Datadock <br/>
          NAF : 8559A <br/>
          TVA Intra : FR 04 752 389 098. <br/>
        </p>
        <p>Le cabinet Prairial intervient également en conseil en RH, management, conduite du changement, relations interpersonnelles</p>
      </div>

      <h3>DIRECTEUR DE PUBLICATION</h3>
      <p className="jenny"> Jenny Augias</p>

      <h4>INFORMATIQUE ET LIBERTES</h4>
      <p>
        Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et visiteurs du site Prairial les présentes mentions légales.
      </p>
      <p>
        Le site Prairial est accessible à l’adresse suivante : www.prairial.com (ci-après « le Site »). L’accès et l’utilisation du Site sont soumis aux présentes « Mentions légales » détaillées ci-après ainsi qu’aux lois et/ou règlements applicables.
      </p>

      <p>
        La connexion, l’utilisation et l’accès à ce Site impliquent l’acceptation intégrale et sans réserve de l’internaute de toutes les dispositions des présentes Mentions Légales.
      </p>

      <h5>
        ARTICLE 1 – INFORMATIONS LÉGALES
      </h5>
      <p>
        En vertu de l’Article 6 de la Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, il est précisé dans cet article l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi. Le site Prairial est édité par : Prairial ayant son siège social à l’adresse suivante : 361 rue Lecourbe, et immatriculée au numéro suivant : RCS PARIS 752 389 098. Téléphone : 06 47 81 38 73 Adresse e-mail : contact@prairial.com ci après » l’Éditeur « Le Directeur de publication est : Jeny Augias Adresse e-mail de contact : contact@prairial.com ci après » le Directeur de publication « Le site Prairal est hébergé par : OVH dont le siège est situé à l’adresse suivante : 2 rue Kellermann – 59100 Roubaix – France. Téléphone : +33 9 72 10 10 07 ci après » l’Hébergeur « Sont considérés comme utilisateurs tout les internautes qui naviguent, lisent, visionnent et utilisent le site Prairial. ci après les » Utilisateurs « .
      </p>

      <h5>
        ARTICLE 2 – ACCESSIBILITÉ
      </h5>
      <p> Le Site est par principe accessible aux utilisateurs 24/24h et 7/7j, sauf interruption, programmée ou non, pour des besoins de maintenance ou en cas de force majeure. En cas d’impossibilité d’accès au Site, celui-ci s’engage à faire son maximum afin d’en rétablir l’accès. Le Site ne saurait être tenu pour responsable de tout dommage, quelle qu’en soit la nature, résultant de son indisponibilité.
      </p>

      <h5> ARTICLE 3 – COLLECTE DE DONNÉES ET LOI INFORMATIQUE ET LIBERTÉS
      </h5>
      <p> Ce site est déclaré à la Commission Nationale Informatique et Libertés (CNIL). En outre, il est conforme aux dispositions de la Loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés. En vertu de celle-ci l’Utilisateur bénéficie notamment d’un droit d’opposition (art. 32 et 38), d’accès (art. 38 et 39) et de rectification (art. 40) des données le concernant. Pour faire usage de celui-ci, l’Utilisateur doit s’adresser à l’Éditeur en le contactant à l’adresse suivante : contact@activpartners.fr, ou par courrier adressé au siège social de l’Éditeur en précisant ses nom, prénom(s), adresse et adresse(s) e-mail.
      </p>

      <h5> ARTICLE 4 – POLITIQUE DE COOKIES
      </h5>
      <p> Le site Prairial a éventuellement recours aux techniques de « cookies » lui permettant de traiter des statistiques et des informations sur le trafic, de faciliter la navigation et d’améliorer le service pour le confort de l’Utilisateur. Conformément à la législation européenne, le site Prairial a mis à jour sa politique de confidentialité en matière de cookies. L’Utilisateur est libre d’accepter ou de refuser les cookies de tous les sites internet en modifiant les paramètres de son navigateur internet.
      </p>

      <h5> ARTICLE 5 – LOI APPLICABLE ET JURIDICTION
      </h5>
      <p> Les présentes Mentions Légales sont régies par la loi française. En cas de différent et à défaut d’accord amiable, le litige sera porté devant les tribunaux français conformément aux règles de compétence en vigueur.
      </p>

      <h5> ARTICLE 6 – CONTACT
      </h5>
      <p> Pour tout signalement de contenus ou d’activités illicites, l’Utilisateur peut contacter l’Éditeur à l’adresse suivante : contact@prairial.com, ou par courrier recommandé avec accusé de réception adressé à l’Éditeur aux coordonnées précisées dans les présentes mentions légales.
      </p>

      <h4> POLITIQUE DE CONFIDENTIALITÉ
      </h4>
      <p> Vous disposez d’un droit d’accès, de rectification et d’opposition pour motif légitime sur vos données, ainsi que, à compter de l’entrée en application du Règlement Général sur la Protection des Données Personnelles (25 mai 2018), d’un droit à l’effacement, à la portabilité de vos données et d’un droit à la limitation du traitement de vos données. Pour exercer ces droits, veuillez adresser votre demande à contact@prairial.com.
      </p>

      <p className="titleName">
        Le cabinet Prairial vous souhaite une excellente navigation !
      </p>

    </LegalPageStyled>
  );
};

const LegalPageStyled = styled.div`
  max-width: 600px !important;
  padding: 40px;
  
  * {
    font-weight: normal !important;
  }
  
  h1 {
    font-size: ${ font7 };
    font-weight: bold !important;
  }
  
  .titleName, h3 {
    color: ${ mainColor };
    font-weight: bold !important;
  }
  
  .jenny {
    font-weight: bold;
    font-size: ${ font3 };
  }
  h3 {
    font-size: ${ font4 };
    font-weight: bold !important; 
  }
  
  h4, h5 {
    font-size: ${ font3 };
    font-weight: bold !important;
  }
`;

export default LegalPage;






























