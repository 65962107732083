import OurClientReturnContainer from 'containers/our-client-return/our-client-return.container';
import React, { FC } from 'react';
import styled from 'styled-components';
import { font4, offerColor, offerGradient } from 'styles/variables';

interface Props {

}

const ClientComponent: FC<Props> = () => {
  return (
    <ClientComponentStyled>
      <h2 className="offreTitle">TÉMOIGNAGES CLIENT</h2>
      <div className="elements">
        <OurClientReturnContainer showTitle={ false }/>
      </div>

    </ClientComponentStyled>
  );
};

const ClientComponentStyled = styled.div`
  .elements {
    margin-top : -40px;

    .testimonial__enterprise {
      color : ${ offerColor } !important;
    }

    * {
      font-weight : bold !important;
      font-size   : ${ font4 };
    }
    
  .swiper-pagination-bullet-active {
    background : ${ offerGradient } !important;
  }
  }

`;

export default ClientComponent;

