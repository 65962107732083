import React  from 'react';
import styled from 'styled-components';

const Bold = styled.span`
  //font-weight: 600;
`;

export const ourMissionText = [
  <p>
    <Bold>Composé d’une équipe de coachs et formateurs expérimentés en management</Bold>, Prairial travaille avec des organisations de tous types et met à leur disposition les techniques les plus innovantes en terme de management issus de l’environnement start up à impact.
  </p>,
  <p>
    <Bold>Prairial a pour objectif d’accompagner les organisations dans leur transformation managériale</Bold> pour les aider à retrouver un fonctionnement aligné avec les attentes du marché, retrouvez performance, sens et innovation.
  </p>,
  <p>
    <Bold>Notre ambition : <br/> de permettre à ces clients de retrouver innovation structurelle</Bold> innovation structurelle, performance, fidélisation des collaborateur et des clients.
  </p>
];

export const ourMissionIcon = [
  {
    title : <>TRANFORMATION <br/> MANAGERIALE</>,
    deg : 0,
    margin : '16px'
  }, {
    title : <>INTELLIGENCE <br/> COLLECTIVE</>,
    deg : -90,
    margin : 0,
    marginBottom: '-2px'
  }, {
    title : <>CRÉATION & <br/>INNOVATION</>,
    deg : -60,
    margin : 0,
    marginBottom : '4px'
  }
];
