import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import { size3 } from 'styles/variables';
import img from '../../resources/offer/cible.svg';

const text1 = 'Il s’adresse à vous si vous êtes :';
const p1 = 'RH manager, chef de projet, expert, consultant, indépendant';
const p2 = 'quel que soit votre secteur d’activité et organisation.';

const text2 = <> Et que vous êtes prêts à : <br/>
                 - Traiter vos défis professionnels actuels en groupe ? <br/>
                 - Passer à l’action de manière efficace et innovante ? <br/>
                 - Apprendre ensemble autrement, partager vos pratiques ?<br/>
                 - Prendre du recul en contribuant aux défis des autres participants ? </>;

const WhoCodevComponent = ( { isMobile } ) => {
  return (
    <WhoCodevComponentStyled>
      <h2 className="offreTitle">A QUI S’ADRESSE L’ATELIER CODEV’ ?</h2>
      <div className="offerContainer">
        {
          isMobile ? (
            <Flex className="mobile" column>
              <div className="background"/>
              <div className="text">
                <p>{ text1 }{' '}{ p1 }{' '}{ p2 }</p>
                <p className="text_2">{ text2 }</p>
              </div>
              <div>
                <p>{ text1 }{' '}{ p1 }{' '}{ p2 }</p>
                <p className="text_2">{ text2 }</p>
              </div>
            </Flex>
          ) : (
            <Flex className="desktop" justifyAround>
              <FlexItem basis="60%">
                <p>{ text1 }</p>
                <p className="orange_arrow">{ p1 }</p>
                <p className="orange_arrow">{ p2 }</p>
                <p className="text_2">{ text2 }</p>
              </FlexItem>
              <FlexItem basis="30%" className="aimContainer">

              </FlexItem>
            </Flex>
          )
        }
      </div>
    </WhoCodevComponentStyled>
  );
};

const WhoCodevComponentStyled = styled.div`

  .desktop {
    .aimContainer {
      background : url("${ img }") no-repeat center;
    }
  }

  .mobile {
    position   : relative;
    text-align : center;
    z-index    : 100;

    .text {
      z-index  : 0;
      width    : 100%;
      height   : 100%;
      position : absolute;
    }

    .background {
      background            : white;
      z-index               : 0;
      width                 : 100%;
      height                : 100%;
      position              : absolute;
      top                   : 50%;
      right                  : calc(-50% - 24px) ;
      transform             : translate(0%, -50%);
      background            : url("${ img }") no-repeat center;
    }
  }


  p {
    margin : 0;
  }

  .text_2 {
    margin-top : ${ size3 };
  }
`;

export default WhoCodevComponent;

