import React, { FC } from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import { font5, offerColor, size2 } from 'styles/variables';
import img from '../../resources/images/jenny_detouree_chemiseblanche.webp';
import linkedin from '../../resources/offer/icone_linkedin.svg';

interface Props {
  isMobile: boolean
}

const jenny = <>Jenny Augias </>;
const jenny2 = 'Fondatrice et dirigeante de Prairial depuis 2012';
const jenny3 = 'Consultante-coach formatrice en dynamiques managériales';

const text1 = 'J’ai accompagné en formation et codéveloppement plus de 200 managers, entrepreneurs et étudiants. ';
const text2 = 'Je suis certifiée en animation de groupes de codéveloppement et membre de l’ AFCODEV. ';
const text3 = 'J’ai été formée et supervisée dans ma pratique par Sylvie Lambert, pionnière de la démarche en France. ';
const text4 = 'Je suis membre d’un groupe d’intervision depuis 3 ans. ';

const FacilitatriceComponent: FC<Props> = ( { isMobile } ) => {
  return (
    <FacilitatriceComponentStyled>
      <h2 className="offreTitle">FACILITATRICE</h2>
      <div className="offerContainer">

        {
          isMobile ? (
            <Flex className="mobile" column>
              <h5>{ jenny }</h5>
              <p className="doing">{ jenny3 }</p>

              <FlexItem basis="30%" className="jennyPic">
                <Flex alignTop justifyCenter full>
                  <img src={ img } width={ 250 } alt="photo de jenny"/>
                  <a href="https://www.linkedin.com/in/jennyaugias" className="linkedin" >
                    <Flex column alignCenter>
                      <img src={ linkedin } width={ 50 } alt="linkedn"/>
                      <p>+ de detail</p>
                    </Flex>
                  </a>
                </Flex>
              </FlexItem>

              <FlexItem basis="60%">
                <Flex column justifyBetween full>
                  <p>{ text1 }
                    { text2 }
                    { text3 }
                    { text4 }</p>
                </Flex>
              </FlexItem>
            </Flex>

          ) : (

            <Flex className="desktop" justifyBetween>

              <FlexItem basis="30%" className="jennyPic">
                <img src={ img } width={ 250 } alt="photo de jenny"/>
                <a href="https://www.linkedin.com/in/jennyaugias" className="linkedin">
                  <Flex column alignCenter>
                    <img src={ linkedin } width={ 50 } alt="linkedn"/>
                    <p>+ de detail</p>
                  </Flex>
                </a>
              </FlexItem>

              <FlexItem basis="60%">
                <Flex column justifyBetween full>
                  <h5 className="orange">{ jenny } <br/> { jenny2 }</h5>
                  <p className="orange">{ jenny3 }</p>
                  <p>{ text1 }</p>
                  <p>{ text2 }</p>
                  <p>{ text3 }</p>
                  <p>{ text4 }</p>
                </Flex>
              </FlexItem>
            </Flex>

          )
        }
      </div>

    </FacilitatriceComponentStyled>
  );
};

const FacilitatriceComponentStyled = styled.div`
  .mobile {
    .doing {
      text-align : center;
    }

    .linkedin {
    margin-top: ${size2};
    }
    
    .jennyPic {
      margin-bottom: ${ size2 };
    }
    
    p {
      text-align: center;
    }

  }

  .orange {
    color : ${ offerColor };
  }

  h5 {
    text-align  : center;
    font-size   : ${ font5 };
    font-weight : bolder;
  }

  .jennyPic {
    position : relative;
  }


  .desktop {

    .linkedin {
      right    : -7%;
      top      : 10%;
      position : absolute;
    }
  }
`;

export default FacilitatriceComponent;

