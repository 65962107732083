import React from 'react';
import arrow_and_man from '../../resources/svg/brainstorm yellow.svg';
import n1 from '../../resources/svg/n1_vert.svg';
import n2 from '../../resources/svg/n2_jaune.svg';
import n3 from '../../resources/svg/n2_rouge.svg';
import arrow_and_light from '../../resources/svg/picto_ampoule_et_fleche.svg';
import arrow_and_computer from '../../resources/svg/picto_ordi_et_fleche.svg';

export const SIZE_ELEMENT = 270;
export const BIG_ELEMENT = 1110;

export const ourValueOfferData = [
  {
    number          : n1,
    title           : <>FORMATION <br/>MANAGÉRIALES <br/>INNOVANTES </>,
    titleMinus      : <>CHANGEMENT DE POSTURE <br/>DE VOS MANAGERS</>,
    titleMinusModal : <>CHANGEMENT DE POSTURE DE VOS MANAGERS</>,
    textColor       : '',
    imgModal        : arrow_and_computer,
    titleModal      : 'FORMATION\nMANAGERIALES\nINNOVANTES ',
    textModal       : [
      'Développer l’intelligence collective et émotionnelle des managers.',
      'Faire évoluer la posture du manager vers celle de leader inspirant.',
      'Mettre à disposition un kit d’outils pragmatiques au service ' +
      'en savoir du management positif et collaboratif.'
    ],
    sizeImg         : 100,
    className       : 'button--blue',
    sizeElement     : SIZE_ELEMENT
  },
  {
    number          : n2,
    title           : <>ATELIERS <br/>CO-DÉVELOPPEMENT <br/></>,
    titleMinus      : <>VOS MANAGERS PEUVENT <br/>RESOUDRE LEURS <br/> CHALLENGES</>,
    titleMinusModal : <>VOS MANAGERS PEUVENT RESOUDRE LEURS CHALLENGES</>,
    textColor       : 'text--yellow',
    imgModal        : arrow_and_man,
    titleModal      : 'ATELIER \nCO-DEV\n',
    textModal       : [
      'Développer la capacité de résolution de challenges managériaux grâce au co-développement dans sa forme classique ou en flash-codév’.',
      'L’objectif est de rendre indépendantes les organisations dans l’utilisation de cette méthode innovante.'
    ],
    sizeImg         : 70,
    className       : 'button--yellow',
    sizeElement     : SIZE_ELEMENT,
    button: <>Découvrez l'offre <br/> Atelier Codev</>
  },
  {
    number          : n3,
    title           : <>WORKSHOPS <br/>DESIGN THINKING </>,
    titleMinus      : <>VOS EQUIPES SONT <br/>IMPLIQUEES DANS LES <br/> CHANGEMENTS</>,
    titleMinusModal : <>VOS EQUIPES SONT IMPLIQUEES DANS LES CHANGEMENTS</>,
    textColor       : 'text--red',
    titleModal      : 'WORKSHOP\nDESIGN\nTHINKING',
    textModal       : [ 'Impulser une dynamique d’équipe performante via des ateliers de Design Thinking.',
      'Réussir à fédérer un grand nombre d\'acteurs pour trouver une réponse innovante à un problème complexe, de façon rapide' ],
    imgModal        : arrow_and_light,
    sizeImg         : 70,
    className       : 'button--red',
    sizeElement     : SIZE_ELEMENT
    // sizeElement: 1110
  }
];

