import 'bootstrap/dist/css/bootstrap.min.css';
import OfferPage from 'pages/offer/offer.page';
import React, { useEffect, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import ScrollToTop from 'react-scroll-up';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import 'swiper/css/swiper.css';
import LegalPage from './pages/legalPage';
import MainPage from './pages/main.page';
import TeamPage from './pages/teamPage/team.page';
import flecheUp from './resources/svg/fleche_up.svg';
import './scss/App.scss';
import AppStyled from './styles/app.style';
import { desktopSize } from './styles/variables';

const links = [
  { text : 'Accueil', path : 'home' },
  { text : 'Notre vision', path : 'vision' },
  { text : 'Vous Etes', path : 'youAre' },
  { text : 'Nos Objectifs', path : 'ourOb' },
  { text : 'Notre Approche', path : 'approche' },
  { text : 'Nos clients', path : 'ourClient' },
  { text : 'nos differenciants', path : 'ourSkills' },
  { text : 'retours clients', path : 'ourReturnClient' },
  { text : 'l\'equipe', path : 'team' },
];

const ButtonContainer = styled.div`
  z-index: 10000;
  button {
    border: none;
    background: none;
    
    img {
      width: 50px;
    }
  }
`;

const Hamburger = () => {
  const isDesktopOrLaptop = useMediaQuery( {
    query : `(min-width: ${ desktopSize })`
  } );

  const [ isOpen, setOpen ] = useState( false );
  return (
    <Menu width={ isDesktopOrLaptop ? '50%' : '100%' } isOpen={ isOpen }
          onStateChange={ ( state ) => setOpen( state.isOpen ) }>
      {
        links.map( ( el, i ) =>
          <NavHashLink to={ '/#' + el.path } key={ i }
                       activeClassName="selected"
                       isActive={ () => {
                         let pathname = document.location.hash.substr( 1 );
                         return pathname === el.path;
                       } }
                       smooth>
            <p className="menu-item" onClick={ () => setOpen( false ) }>
              { el.text }
            </p>
          </NavHashLink>
        )
      }
      <Link to="/offre">
        <p className="menu-item" onClick={ () => setOpen( false ) }>
          Atelier Codev
        </p>
        </Link>
    </Menu>
  );
};

const App: React.FC = () => {
  useEffect( () => {
    const { hash } = window.location;
    if ( hash ) {
      const id = hash.replace( '#', '' );
      const element = document.getElementById( id );
      if ( element ) {
        element.scrollIntoView( { block : 'end', behavior : 'smooth' } );
      }
    }
  }, [] );

  return (
    <AppStyled className="App">
      <BrowserRouter>

        <ScrollToTop showUnder={ 160 } style={ { zIndex : 100 } }>
          <ButtonContainer>
            <button>
              <img src={ flecheUp } alt="fleche up"/>
            </button>
          </ButtonContainer>
        </ScrollToTop>

        <Hamburger/>

        <Switch>
          <Route path="/team">
            <TeamPage/>
          </Route>
          <Route path="/legal">
            <LegalPage/>
          </Route>
          <Route path="/offre">
            <OfferPage/>
          </Route>
          <Route path="/">
            <MainPage/>
          </Route>

        </Switch>

      </BrowserRouter>
    </AppStyled>
  );
};

export default App;
