import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import aim from '../../resources/svg/cible_mobile.svg';

const OurAimComponentMobile = () => {
  const isBig = useMediaQuery( {
    query : `(min-width: 450px)`
  } );

  return (
    <OurAimComponentMobileStyled className="myContainer">
      <div id="ourAim" style={ { position : 'relative', overflow : 'hidden' } }>
        <Flex alignItem justifyCenter>
          <FlexItem basis={ isBig ? '80%' : '100%' }>
            <p className="red-list">Managers <br/> de directions métier <br/>
                                    (Project, Vente etc...)</p>

            <p className="red-list">Directions transverses :
              <br/>DRH, Direction de l’Innovation, <br/>de la Transformation,<br/> du Changement
            </p>
            <p className="red-list"> Directions générales et <br/> Comités Exécutifs </p>

          </FlexItem>
          {
            isBig ?
            <FlexItem basis='20%' classname="cibleContainer">
              <div className="cible">
                <img src={ aim } alt=""/>
              </div>
            </FlexItem> : null
          }
        </Flex>
      </div>

    </OurAimComponentMobileStyled> );
};

const OurAimComponentMobileStyled = styled.div`
  position : relative;

  #ourAim {
    margin    : auto;
    max-width : 600px !important;
  }

  p {
    font-weight : bold;
    font-size   : 18px;
  }

  .cible {
    width     : 250px;
    position  : absolute;
    top       : 50%;
    transform : translate(-0%, -50%);
    right     : -125px;
  }
`;

export default OurAimComponentMobile;
