import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import architecture from '../resources/svg/architecture.svg';
import brainstorm from '../resources/svg/brainstorm.svg';
import graphicDesign from '../resources/svg/graphic-design.svg';
import idea from '../resources/svg/idea.svg';
import { font3, maxContainerSize, size3, size4 } from '../styles/variables';

interface Props {
}

const iconArray = [
  { img : idea, text : 'Relancer l’innovation\n' },
  { img : brainstorm, text : 'Attirer de nouveaux talents et retenir les potentiels.' },
  { img : graphicDesign, text : 'Devenir une entité agile et apprenante' },
  { img : architecture, text : 'Mieux anticiper les usages et les besoins.' }
];

const OurVisionItemComponent = ( { img, text } ) =>
  <OurVisionItemComponentStyled column alignCenter>
    <FlexItem>
      <img className="item-icon" src={ img } alt="logo"/>
    </FlexItem>
    <FlexItem>
      <p className="pre" style={ { textAlign : 'center' } }>{ text }</p>
    </FlexItem>
  </OurVisionItemComponentStyled>;

const OurVisionItemComponentStyled = styled( Flex )`
  img {
    display: block;
    margin: auto;
    padding-bottom: 20px
  }
`;

const WeDoContainer: FC<Props> = () => {
  const setIcon2lines = useMediaQuery( { minWidth : 450 } );
  const setIcon4lines = useMediaQuery( { minWidth : 1000 } );

  let iconWidth = '100%';
  iconWidth = setIcon2lines ? '50%' : iconWidth;
  iconWidth = setIcon4lines ? '20%' : iconWidth;

  return (
    <WeDoContainerStyled className="myContainer">
      <h3
        className="bigTitle">Afin de <span className="underlineBig">libérer</span> {' '}
                             cette dynamique, Prairial vous accompagne pour
        { ' ' }<span className="underlineBig">réinventer</span> votre <span
          className="underlineBig">modèle managérial</span></h3>

      <Flex className="icon-container myContainer" justifyBetween wrap="true">
        {
          iconArray.map(
            ( el, i ) =>
              <FlexItem basis={ iconWidth } key={ i }>
                <OurVisionItemComponent { ...el }/>
              </FlexItem>
          )
        }
      </Flex>
    </WeDoContainerStyled>
  );
};

const iconSize = 135;

const WeDoContainerStyled = styled.div`
  max-width : ${ maxContainerSize };
  margin    : auto;
  
  .bigTitle {
  padding: ${ size3 } 0;
  }

  .icon-container {
    margin : auto;

    .item-icon {
      margin : ${ size4 } auto auto;
      height : ${ iconSize }px;
      width  : ${ iconSize }px;
    }
  }
  
  p {
    font-size: ${ font3 };
   font-weight: bold; 
  }
`;

export default WeDoContainer;

