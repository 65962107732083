import React from 'react';
import styled from 'styled-components';
import Call2Action from '../../components/callToAction.component';
import { SectionTitle } from '../../styles/font';
import { mainGradient } from '../../styles/variables';

const ContactContainer = () => {

  return (
    <div style={ { marginTop : '32px', marginBottom : '40px' } }>

      <SectionTitle justifyCenter>
        <h1>Contact</h1>
      </SectionTitle>
      <div style={ { marginTop : '24px', marginBottom : '40px' } }>
        <Call2Action/>
      </div>
      <Footer>
        <div className="footer">
          <p>Tous droits réservés © 2020 Prairial </p>
          <p>Web design création : nfgraphisme@gmail.com</p>
          <div className="return">
            <p>
              <a href="/legal"> Mentions légales</a>
            </p>
          </div>
        </div>
      </Footer>
    </div>
  );
};

const Footer = styled.div`


   .return {
    background : ${ mainGradient };
    margin: 20px auto;
    padding: 2px 0;
    width: 220px;
    a {
      display: block;
      margin: auto;
      background: white;
      text-align: center;
    font-weight: bold; 
    //font-size: 24px;
    }}

  margin: 20px 0;
  p {
    margin: 0;
    font-weight: bold;
    text-align: center;
  }
`;

export default ContactContainer;
