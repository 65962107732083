import Call2Action from 'components/callToAction.component';
import React, { FC } from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import { size1, size2, size3 } from 'styles/variables';
import bon from '../../resources/offer/2bohommes_modalités_pratiques.svg';
import list from '../../resources/offer/432_modalités_pratiques.svg';
import map from '../../resources/offer/localisation_geographique.svg';

interface Props {
  isMobile: boolean
}

const text1 = `Principes :`;
const text2 = `volontariat, confidentialité, engagement à participer aux séances, bienveillance et respect.`;
const text3 = `L’atelier Codev’ peut être organisé en intra pour une seule entreprise ou en inter pour des participants d’entreprises différentes`;

const ModaliteComponent: FC<Props> = ( { isMobile } ) => {
  return (
    <ModaliteComponentStyled>
      <h2 className="offreTitle">MODALITÉS PRATIQUES</h2>

      <div className="offerContainer">

        <Flex full justifyCenter column={isMobile}>

          <FlexItem basis="50%">

            <Flex column alignCenter className="detail">
              <div>
                <p className="seance">4 séances de 90mn à 2h sur 3 mois</p>
                <img src={ list } alt="list"/>
              </div>

              <Flex alignCenter justifyBetween>
                <FlexItem basis="20%">
                  <img className="modIcon" src={ bon } alt="" width={ 80 }/>
                </FlexItem>
                <FlexItem basis="80%">
                  <p>X 10 maximum, <br/>
                     sans lien hiérarchique</p>
                </FlexItem>
              </Flex>

              <Flex alignCenter justifyBetween>
                <FlexItem basis="20%">
                  <img className="modIcon" src={ map } width={ 35 } alt=""/>
                </FlexItem>
                <FlexItem basis="80%">
                  <p>Lieu : salle cosy <br/>
                     à Paris centre</p>
                </FlexItem>
              </Flex>

            </Flex>

          </FlexItem>

          <FlexItem basis="50%">
            <Flex column full justifyBetween>
              <p>{ text1 } <br/> { text2 }</p>
              <p>{ text3 }</p>
              <Call2Action/>
            </Flex>
          </FlexItem>
        </Flex>

      </div>

    </ModaliteComponentStyled>
  );
};

const ModaliteComponentStyled = styled.div`
  .offreTitle {
    margin-top : 0 !important;
  }

  .modIcon {
    margin-right : ${ size3 };
  }

  .detail {
    & > div {
      margin : ${ size1 } 0;
          }
  }

  .seance {
    margin-bottom: ${ size2 }; 
  }
`;

export default ModaliteComponent;

