import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import jenny from '../../resources/images/jenny_detouree_chemiseblanche.webp';
import { SectionTitle } from '../../styles/font';
import { SectionStyle } from '../../styles/structure';
import { bigDesktopSize, darkColorOnBackground, mainGradient } from '../../styles/variables';
import TeamDetailButtonComponent from './team-detail-button.component';
import TeamItemComponentDesktop from './team-item.component.desktop';
import { teamData } from './team-other.component';

const Team = () =>
  <TeamStyled className="myContainer">
    <SectionTitle justifyCenter><h1 className="titleMarginDesktop">L'Equipe</h1></SectionTitle>

    <Flex justifyBetween>
      {
        teamData.map(
          ( el, index ) => <TeamItemComponentDesktop
            key={ index } { ...el }/> )
      }
    </Flex>
  </TeamStyled>;

const TeamStyled = styled.div`
  padding-bottom: 40px;
`;

const Jenny = () =>
  <JennyStyled id="jenny">
    <div className="myContainer">
      <SectionTitle justifyCenter>
        <h1>Fondatrice & Dirigeante</h1>
      </SectionTitle>

      <Flex alignCenter>
        <FlexItem basis="40%">
          <img className="jenny" src={ jenny } alt=""/>
          <div style={{marginTop: '24px'}}>
            <TeamDetailButtonComponent linkUrl="https://www.linkedin.com/in/jennyaugias"/>
          </div>
        </FlexItem>

        <FlexItem basis="60%">

          <div className="element">
            <p className="name"> Jenny Augias</p>
            {/*<div className="bottomArrow">*/ }
            {/*  <img src={ flecheUp } alt="profile"/>*/ }
            {/*</div>*/ }
            <p className="skill">Consultante-coach,<br/> Formatrice en dynamiques managériales.</p>
          </div>


          <p>
            J’ai créé Prairial en 2012 après 15 ans d’expérience professionnelle dans le conseil et le secteur numérique. Je me suis spécialisée dans l’intelligence relationnelle et le management positif. Ma passion est de créer des approches innovantes et humanistes pour accompagner les managers et leurs équipes vers la performance et le mieux être au travail.
          </p>
          <p>
            Depuis 20 ans, j’observe que la réussite des organisations passe par la capacité des équipes à coopérer c’est-à-dire leur intelligence relationnelle. Je suis toujours étonnée par leur motivation à résoudre les défis technologiques, organisationnels et humains. Je constate aussi leur solitude face à la complexité des projets, les impératifs de qualité de service et de rentabilité.
          </p>
          <p>
            Passionnée par l'innovation managériale et le leadership, je suis persuadée que les start-ups et la nouvelle génération vont contribuer à faire évoluer les pratiques managériales. C’est pourquoi je collabore avec des chercheurs et entrepreneurs sur des projets pédagogiques et managériaux innovants.
          </p>
        </FlexItem>
      </Flex>

      <p className="citation jenny-sep">
        Une vision qui ne s'accompagne pas d'actions n'est
        qu'un rêve. Une action qui ne découle pas d'une vision
        c'est du temps perdu. Une vision suivie d'action
        peut changer le monde. Nelson Mandela
      </p>
    </div>
  </JennyStyled>;

const JennyStyled = styled( SectionStyle )`
  background: ${ mainGradient };
  
  p{ font-weight: bold}
  
  .name {
    font-size: 24px;
    font-weight: bold;
  }
 
  .skill {
  font-size: 20px;
  } 
  .bottomArrow {
    img {
        display: block;
       margin: auto;
      width: 60px;
      transform: rotate(180deg);
    }
  }

  .jenny {
    display: block;
    margin: auto;
    width: 300px;
      border-bottom: ${ darkColorOnBackground } solid 2px;
  }

  .citation {
    font-size: 24px;
    font-weight: normal;
    font-family: 'Homemade Apple', serif;
    position: relative;


    &:after, &:before {
      position: absolute;
      font-size: 90px;
      line-height: 0.1em;
    }

    &:before {
      content: open-quote;
      top: 16px;
      left: -40px;
    }

    &:after {
      bottom: -20px;
      right: 60px;
      content: close-quote;


      @media (min-width: ${ bigDesktopSize }) {
        right: 0;
      }
    }
  }

`;

const TeamComponentDesktop = () =>
  <TeamComponentDesktopStyled>
    <Team/>
    <Jenny/>

  </TeamComponentDesktopStyled>;

const TeamComponentDesktopStyled = styled.div`


  .jenny-sep {
    margin-bottom: 20px;
    margin-top: 60px;
  }


`;

export default TeamComponentDesktop;
