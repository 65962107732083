import styled from 'styled-components';
import flecheUp from '../resources/svg/fleche_up.svg';

export const CarouselStyled = styled.div`

  .slider-control-centerright, .slider-control-centerleft {
    button {
      background : url("${ flecheUp }") no-repeat center !important;
      width      : 55px;
      height     : 55px;
      text-align : center;
    }

  }

  .slider-control-centerright {
    button {
      transform : rotate(90deg);
    }
  }

  .slider-control-centerleft {
    button {
      transform : rotate(-90deg);
    }
  }
 `;
