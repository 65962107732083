import { faLinkedin }      from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React               from 'react';
import styled              from 'styled-components';
import Flex                from 'styled-flex-component';

const TeamDetailButtonComponentStyled = styled.button`
  background: none;
  border: none;
  margin: auto;
  display: block;

  p {
    margin: 0;
    padding-right: 5px;
  }
  
  .svg-inline--fa {
    height: 30px; 
    width: 30px;
  }
`;

const TeamDetailButtonComponent = ( { linkUrl = 'toto' } ) =>
  <TeamDetailButtonComponentStyled>
    <a href={ linkUrl } target="_blank">
      <Flex alignCenter>
        <p>+ détails</p>
        <FontAwesomeIcon icon={ faLinkedin }/>
    </Flex>
    </a>
  </TeamDetailButtonComponentStyled>

export default TeamDetailButtonComponent;
