import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { size1, size2 } from 'styles/variables';
import img from '../../resources/offer/petite_fleche_orange.svg';

const text = `Vous trouverez dans les séances :`;
const text1 = `Un cadre bienveillant et confidentiel qui facilite le partage des pratiques,`;
const text2 = `Une méthode pragmatique pour résoudre vos défis / enjeux du moment,`;
const text3 = `De l’aide de vos pairs sur des problématiques communes`;
const text4 = `Du temps gagné, de l’autonomie, de la prise de hauteur`;
const text5 = `Un espace pour développer vos soft-skills : écoute active, assertivité, empathie, critique constructive, pensée divergente, prise de parole, présentation percutante etc..`;

const WhyComponent = ( { isMobile } ) => {
  return (
    <WhyComponentStyled>
      <h2 className="offreTitle">POURQUOI PARTICIPER À L’ATELIER CODEV’ ?</h2>
      <div className="offerContainer">
        {
          isMobile ? (
            <Flex column className="mobile" alignCenter>
              <p>{ text }</p>
              <img src={ img } width={ 80 } alt="fleche"/>
              <p> • { text1 }</p>
              <p> • { text2 }</p>
              <p> • { text3 }</p>
              <p> • { text4 }</p>
              <p> • { text5 }</p>
            </Flex>
          ) : (
            <Flex column alignCenter className="desktop">
              <p>{ text }</p>
              <img src={ img } width={ 80 } alt="fleche"/>
              <p className="orange_arrow">{ text1 }</p>
              <p className="orange_arrow">{ text2 }</p>
              <p className="orange_arrow">{ text3 }</p>
              <p className="orange_arrow">{ text4 }</p>
              <p className="orange_arrow">{ text5 }</p>
            </Flex>
          )
        }
      </div>
    </WhyComponentStyled>
  );
};

const WhyComponentStyled = styled.div`
  text-align: center;
  
  .desktop {
    img {
      margin: ${ size2 };
    }
    p {
      margin-bottom: ${ size1 };
      text-align: left;
    }
  }
  
  .mobile {
    img {
      margin: ${ size2 };
    }
    p {
      margin-bottom: ${ size1 };
    }
  }

`;

export default WhyComponent;

