import React, { FC } from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import explosion from '../../resources/images/explosion.webp';
import { darkColor, rem2, rem6, size3 } from '../../styles/variables';
import LogoComponent from './logo.component';

const HeroComponent: FC = () => (
  <HeroComponentStyled id="home">
    <LogoComponent/>
    <Flex column justifyCenter alignCenter full>
      <p className="title">
        Apporter aux <br/> entreprises
      </p>
      <p className="title title--background">
        des approches innovantes
        <br/>{ ' ' } et humanistes de développement
        <br/>{ ' ' } de la performance
      </p>
    </Flex>
  </HeroComponentStyled>
);

const HeroComponentStyled = styled.div`

  background : url(${ explosion }) no-repeat center;
  height     : 100vh;
  overflow: hidden;

  .title {
    padding     : 0;
    margin      : 0;
    font-weight : 700;
    color       : ${ darkColor };
    text-align  : center;
    font-family : 'Bellota', cursive;

    &--background {
      display          : block;
      transform        : rotate(-2deg);
      background-color : ${ darkColor };
      color            : #fff;
      mix-blend-mode   : multiply;
    }
  }

  @media ( max-width : 700px) {
    background-size : 150%;

    .title {
      font-size     : ${ rem6 };
      line-height   : 1.2;
      margin-bottom : ${ size3 };

      &--background {
        font-size : ${ rem2 };
        max-width : 400px;
        padding   : 4px 8px 8px;
      }
    }
  }
  
  @media ( min-width : 440px) {
    .title {
      font-size: 4rem;
      
      &--background {
      font-size: 2rem;
      }
    }
  }
 

  @media ( min-width : 440px) {
    .title {
      font-size : 4rem;

      &--background {
        font-size : 2rem;
      }
    }
  }


  @media ( min-width : 700px) {
    background-size : 105%;

    .title {
      line-height   : 1.2;
      font-size     : 55px;
      margin-bottom : ${ size3 };

      &--background {
        font-size : 30px;
        padding   : 6px 16px 16px;
      }
    }
  }

`;

export default HeroComponent;
