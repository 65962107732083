import React, { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';
import Call2Action from '../../components/callToAction.component';
import { desktopSize } from '../../styles/variables';
import TeamComponentDesktop from './team.component.desktop';
import TeamComponentMobile from './team.composent.mobile';

const TeamContainer = () => {
  const isDesktopOrLaptop = useMediaQuery( {
    query : `(min-width: ${ desktopSize })`
  } );

  return (
    <div id="team">
      { isDesktopOrLaptop ?
        <TeamComponentDesktop/> :
        <TeamComponentMobile/>
      }
    </div>
  );
};

export default TeamContainer;
