import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import Call2Action from '../../components/callToAction.component';
import mailIcon from '../../resources/icon/mailIcon.svg';
import phoneIcon from '../../resources/icon/phoneIcon.svg';
import workPlacePick from '../../resources/images/Asset 19.webp';
import checkIcon from '../../resources/svg/check.svg';
import placeIcon from '../../resources/svg/place.svg';
import searchIcon from '../../resources/svg/search.svg';
import warning from '../../resources/svg/warning.svg';
import { SectionStyle } from '../../styles/structure';
import {
  darkColorOnBackground,
  desktopSize,
  firstGradient,
  font3,
  font6,
  font7,
  mainColorRgb,
  size2,
  size3,
  size5,
  size7
} from '../../styles/variables';

const frictionData = [
  {
    title   : 'Notre Constat',
    img     : searchIcon,
    element : [
      {
        img  : searchIcon,
        text : <>
          Vos organisations sont encore <span className="underlineBig">trop pyramidales</span>.
          Elles freinent vos managers et entravent leur succès à moyen ou long terme.
        </>
      },
      {
        img  : placeIcon,
        text : <>
          Vos <span className="underlineBig"> managers</span> { ' ' }
          sont les acteurs clé de votre
          { ' ' } <span className="underlineBig">transformation:</span>
          <br/>
          ils ont la capacité de fidéliser et motiver leurs collaborateurs,
          d’accélérer leur responsabilisation et leur capacité d’innovation.
          Ils sont les <span
          className="underlineBig">moteurs</span> de la performance et du bien être au travail de votre organisation.
        </>
      }
    ]
  },
  {
    title   : 'Notre Solution',
    img     : warning,
    element : [
      {
        img  : warning,
        text : <>
          Il est indispensable d’investir dans l’<span className="underlineBig">innovation managériale</span>: <br/>
          un changement de posture qui vise à ré-inventer les pratiques managériales
          et modes de travail dont les équipes ont besoin pour <span
          className="underlineBig">exprimer leur potentiel</span>.
        </>
      }
    ]
  },
  {
    title   : 'Les Bénéfices',
    img     : checkIcon,
    element : [
      {
        img  : checkIcon,
        text : <>
          L’innovation managériale permet d’améliorer la <span
          className="underlineBig">qualité de vie</span> au travail et la performance de vos équipes durablement.
        </>
      }, {
        img  : checkIcon,
        text : <>
          C’est à travers vos managers que vous allez améliorer l’engagement et la responsabilisation des équipes,
          mettre en place de nouvelles pratiques, favoriser l’innovation
          et la créativité avec un important <span className="underlineBig">retour sur investissement</span>.
        </>
      }
    ]
  }
];

const FrictionElement = () => {
  //const isBig = useMediaQuery( {
  //  query : `(min-width: 550px )`
  //} );

  return (
    <FrictionElementStyled className="myContainer">
      <div className="needsContainer">
        {

          frictionData.map( ( { title, element, img }, index ) =>
            <div key={ index }>
              <Flex alignCenter className="frictionTitle">
                <img className="frictionTitle__image" src={ img } alt="check icon"/>
                <p className="frictionTitle__title">{ title }</p>
              </Flex>
              {
                element.map( ( { text }, index ) =>
                  <Flex key={ index } className="needs">
                    <p>
                      { text }
                    </p>
                  </Flex>
                )
              }
            </div>
          )
        }
      </div>

    </FrictionElementStyled>
  );
};

const FrictionElementStyled = styled.div`
  margin-top : ${ size5 };

  .frictionTitle {
  margin-bottom: ${ size2 };
 

    &__title {
      font-family : "Bellota", cursive;
      font-weight : bolder;
      font-size   : 30px;
      margin      : 0;
    }

    &__image {
      margin-right : ${ size2 };
    }
  }

  .needsContainer {
    max-width : 600px !important;
    margin    : ${ size3 } auto 0 auto;
    padding   : ${ size3 } 0;

    .needs {
      margin-bottom : ${ size3 };
      
      p {
        font-size   : ${ font3 };
        font-weight : bold;
        margin      : 0;
      }
    }
  }

`;

const VisionComponent = () => {
  const isDesktopOrLaptop = useMediaQuery( { query : `(min-width: ${ desktopSize })` } );

  return (
    <VisionComponentStyled imgBack={ isDesktopOrLaptop ? workPlacePick : undefined } id="vision">

      <div className="womanContainer">

        <Flex>
          {
            isDesktopOrLaptop ?
            <FlexItem className="womanPic">
              <div className="gradient"></div>
            </FlexItem> : null
          }

          <FlexItem basis={ isDesktopOrLaptop ? '50%' : '100%' } className="myContainer introText ">
            <p
              className="textIntro">Dans un monde en pleine transformation, vos managers sont devenus les acteurs clés de l’attractivité, de l’innovation et de l’agilité des organisations.</p>
          </FlexItem>

        </Flex>
      </div>

      <FrictionElement/>

      <div style={{marginBottom: '40px'}}>
        <Call2Action/>
      </div>

    </VisionComponentStyled>
  );
};

interface PropsStyled {
  imgBack: string | undefined
}

const VisionComponentStyled = styled( SectionStyle )<PropsStyled>`
  padding-bottom: 0;
  
  .gradient {
    background: ${ firstGradient };
    width: 100%;
    height: 100%;
    //opacity: .9;
  }
  
  .womanContainer {
    background : rgb(${ mainColorRgb });
  }

  .womanPic {
    background-image    : url("${ ( { imgBack } ) => imgBack }");
    background-repeat   : no-repeat;
    background-position : 0 55%;
    background-size     : cover;
    width: 50%;
  }

  .introText {
    padding             : ${ size7 } 0;
    color     : ${ darkColorOnBackground };
    max-width : 700px !important;
    margin    : auto;

    .textIntro {
      font-family : "Bellota", cursive;

      @media ( max-width : 700px) {
        font-size : ${ font6 };
      }

      font-size   : ${ font7 };
    }
  }
`;

export default VisionComponent;











