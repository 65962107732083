import React from "react";
import { useMediaQuery } from "react-responsive";
import { desktopSize } from "../../styles/variables";
import OurMissionComponentDesktop from "./our-mission.component.desktop";
import OurMissionComponentMobile from "./our-mission.component.mobile";


const OurMissionContainer = () => {

	const isDesktopOrLaptop = useMediaQuery( {
		query: `(min-width: ${ desktopSize })`
	} );

	return (
			<div id="ourOb" className="myContainer"
					 style={ { marginBottom: "24px" } }>
				<h3 className="bigTitle" style={ { margin: "16px auto 48px auto" } }>
					Prairial a pour objectif de vous <span
						className="underlineBig">accompagner</span> dans l’optimisation de vos <span
						className="underlineBig">changements</span> grâce à de <span
						className="underlineBig">nouvelles pratiques</span> <span
						className="underlineBig">managériales</span> sur 3 niveaux :
				</h3>

				{ isDesktopOrLaptop ?
						<OurMissionComponentDesktop/> :
						<OurMissionComponentMobile/>
				}
			</div>
	);
};

export default OurMissionContainer;
