import Carousel, { CarouselProps } from 'nuka-carousel';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { CarouselStyled } from '../../styles/carousel.styled';
import ClientDesktopCarouselDescriptionElement from './clientDesktopCarousel/ClientDesktopCarouselDescription.element';
import ClientDesktopCarouselLogoElement from './clientDesktopCarousel/ClientDesktopCarouselLogo.element';
import { ourClientDataDesktop } from './our-client.data.desktop';

export const ClientLogoCarousel = ( { ourClientData, switchColor, slidesToShow = 3 }: any ) => {

  const defaultControlsConfig = {
    nextButtonText : ' ',
    prevButtonText : ' '
  };

  const carouselProps: CarouselProps = {
    slidesToShow,
    autoplay                   : false,
    cellAlign                  : 'center',
    renderBottomCenterControls : () => undefined,
    afterSlide                 : switchColor,
    speed                      : 800,
    slideIndex                 : 0,
    heightMode                 : 'max',
    defaultControlsConfig
  };

  return (
    <Carousel { ...carouselProps } >
      {
        ourClientData.map( ( el, i ) =>
          <ClientDesktopCarouselLogoElement  { ...el as any } key={ i }/>
        )
      }
    </Carousel>
  );
};

const ClientTextCarousel = ( { slides, currentIndex } ) => {

  const carouselProps: CarouselProps = {
    slidesToShow    : 1,
    autoplay        : false,
    withoutControls : true,
    slideIndex      : currentIndex,
    transitionMode  : 'fade',
    speed           : 800,
    heightMode      : 'first'
  };

  return (
    <Carousel { ...carouselProps } >
      {
        slides.map( ( { text }, i ) =>
          <ClientDesktopCarouselDescriptionElement key={ i } text={ text }/>
        )
      }
    </Carousel>
  );
};

const OurClientComponentDesktop = () => {

  const [ state, setState ] = useState( [ ...ourClientDataDesktop ] );
  const [ currentIndex, setCurrentIndex ] = useState( 0);

  const switchColor = ( index ) => {
    const tmpState = state.map( ( el ) => ( { ...el, state : false } ) );
    tmpState[ index ].state = !tmpState[ index ].state;
    setState( tmpState );
    setCurrentIndex( index );
  };

  return (
    <OurClientComponentDesktopStyled>
      <CarouselStyled>
        <ClientLogoCarousel ourClientData={ state } switchColor={ switchColor }/>

        <ClientTextCarousel slides={ state } currentIndex={ currentIndex }/>
      </CarouselStyled>

    </OurClientComponentDesktopStyled>
  );
};

const OurClientComponentDesktopStyled = styled( Container )``;

export default OurClientComponentDesktop;
