import React, { FC } from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';

interface Props {
  state: boolean;
  logoSize: number;
  imgDefault: string;
  imgActive: string;
}

const ClientDesktopCarouselLogoElement: FC<Props> = ( { state, logoSize = undefined, imgDefault } ) => {
  return (
    <ClientDesktopCarouselLogoElementStyled justifyCenter alignCenter full logoSize={ logoSize } grey={ state ? 0 : 1 }>
      <img src={ imgDefault } alt="logo"/>
    </ClientDesktopCarouselLogoElementStyled>
  );
};
const ClientDesktopCarouselLogoElementStyled = styled( Flex )<{ logoSize: boolean, grey: number }>`

  img {
    width : ${ ( { logoSize } ) => logoSize ? logoSize : 200 }px;
    filter: grayscale( ${ ( { grey } ) => grey } );
  }

  .blackIcon {
    opacity : 0.7;
  }
`;

export default ClientDesktopCarouselLogoElement;


