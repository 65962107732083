import React, { useState } from 'react';
import { Motion, spring } from 'react-motion';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import { font2, font3, mainColor } from '../../styles/variables';
import { BIG_ELEMENT, ourValueOfferData, SIZE_ELEMENT } from './our-value-offer.data';

const BoxContainer = styled( FlexItem )`
  background: white;
  padding: 20px;
  text-align: center;
  position: relative;
  width: 270px;
  height: 370px;
  overflow: hidden;
  
  h2 {
    font-size: 14px;
  }
  
  button {
    width: 115px;
    border: 0;
  }
`;

const ElementBig = ( { titleModal, imgModal, textModal, className, setBigger, textColor, titleMinus } ) =>
  <ElementBigStyled justifyCenter alignCenter full>

    <FlexItem basis="30%" className="full">
      <Flex column full justifyCenter alignCenter>
        <div>
          <h5>
            { titleModal }
          </h5>
          <h4 className={ 'titleMinus ' + textColor }>{ titleMinus }</h4>
        </div>
        <img width={ 120 } height={ 120 } src={ imgModal } alt="img"/>
      </Flex>
    </FlexItem>

    <FlexItem basis="60%" className="full">
      <Flex column justifyCenter full>
        { textModal.map( ( el, i ) => <p key={ i }>{ el }</p> ) }
        <button className={ className } onClick={ setBigger }>
          Fermer
        </button>
      </Flex>
    </FlexItem>

  </ElementBigStyled>;

const ElementBigStyled = styled( Flex )`
    .titleMinus {
    font-weight : bold;
    font-size   : ${ font3 };
    color       : ${ mainColor };
    margin : 16px 0 32px 0;
  }

  .full {
    height : 100%;
  }

  h5 {
    font-size     : 24px;
    white-space   : pre;
  }

  h4 {
  }
  p {
    font-size  : 20px;
    text-align : left;
  }

`;

const UnSelectedComponent = ( props: any ) => {
  const { number, isBig, title, className, setBigger, titleMinus = '', textColor, button = undefined } = props;
  return (
    <UnSelectedComponentStyled>
      {
        isBig() ?
        <ElementBig { ...props }/>
                :
        <Flex column full justifyAround alignCenter>
          <h2>{ title }</h2>
          <h4 className={ 'titleMinus ' + textColor }>{ titleMinus }</h4>
          <img src={ number } alt="number" height={ 100 }/>
          {
            button ? <Link className={ className + ' offre-codev' } to="/offre">{ button }</Link> :
            <button className={ className }
                    onClick={ setBigger }>
              en savoir +
            </button>
          }
        </Flex>
      }
    </UnSelectedComponentStyled>
  );
};

const UnSelectedComponentStyled = styled.div`
  h4, h2 {
    margin : 0;
  }
  height : 100%;

  .titleMinus {
    font-weight : bold;
    font-size   : ${ font2 };
    color       : ${ mainColor };
  }
  
  .offre-codev {
    padding: 4px 20px;
  }
`;

const BoxElement = ( { setDisplay, index, isOpen, sizeElement, ...props }: any ) => {
  const isBig = () => sizeElement === BIG_ELEMENT;
  const setBigger = () => isBig() ? setDisplay( -1 ) : setDisplay( index );

  return (
    <>
      <Motion
        style={ {
          width   : spring( sizeElement ),
          padding : spring( sizeElement ? 20 : 0 )
        } }>
        {
          style => <BoxContainer style={ style }>
            <UnSelectedComponent { ...props } setBigger={ setBigger } isBig={ isBig }/>
          </BoxContainer>
        }
      </Motion>
    </>
  );
};

const OurValueOfferComponentDesktop = () => {
  const [ state, setState ] = useState( [ ...ourValueOfferData ] );

  const updateValue = ( index ) => {
    if ( index >= 0 ) {
      const newState = state.map( ( el ) => ( { ...el, sizeElement : 0 } ) );
      newState[ index ] = {
        ...newState[ index ],
        sizeElement : BIG_ELEMENT
      };

      setState( newState );
    } else {
      const newState = state.map(
        ( el ) => ( { ...el, sizeElement : SIZE_ELEMENT } ) );

      setState( newState );
    }

  };

  return (
    <OurValueOfferComponentDesktopStyled justifyBetween>
      { state.map( ( el, index ) =>
        <BoxElement key={ index } { ...el } setDisplay={ updateValue }
                    index={ index }/>
      ) }
    </OurValueOfferComponentDesktopStyled> );
};

const OurValueOfferComponentDesktopStyled = styled( Flex )`
  h2 {
    font-size: 20px;
    white-space: pre;
  }
`;

export default OurValueOfferComponentDesktop;
