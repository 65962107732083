import React, { FC } from 'react';
import styled from 'styled-components';
import { offerColor, size0 } from 'styles/variables';

interface Props {
  isMobile: boolean
}

const textStart = 'J’aimerais ...';
const text1 = 'Réussir le « On Boarding » de mes collaborateurs pour mieux les accueillir et les intégrer,';
const text2 = 'Remotiver mon équipe pour la fidéliser et booster les potentiels,';
const text3 = 'Réussir un projet de transformation culturel délicat dans mon organisation,';
const text4 = 'Améliorer la communication interpersonnelle au sein de mon équipe,';
const text5 = 'Lever un blocage lié à une problématique managériale générationnelle,';
const text6 = 'Lever un blocage lié à une problématique managériale générationnelle,';
const text7 = 'Etc...';

const ExempleComponent: FC<Props> = ( { isMobile } ) => {
  return (
    <ExempleComponentStyled>

      <h2 className="offreTitle">EXEMPLES DE SUJETS DE CO-DÉVELOPPEMENT
                                 EN INNOVATION MANAGÉRIALE</h2>
      <div className="offerContainer">

        {
          isMobile ? (
            <div className="mobile">
              <p className="textStart">{ textStart }</p>
              <p>• { text1 }</p>
              <p>• { text2 }</p>
              <p>• { text3 }</p>
              <p>• { text4 }</p>
              <p>• { text5 }</p>
              <p>• { text6 }</p>
              <p>{ text7 }</p>
            </div>
          ) : (
            <div className="mobile">
              <p className="textStart">{ textStart }</p>
              <p>- { text1 }</p>
              <p>- { text2 }</p>
              <p>- { text3 }</p>
              <p>- { text4 }</p>
              <p>- { text5 }</p>
              <p>- { text6 }</p>
              <p>{ text7 }</p>
            </div>
          )
        }
      </div>

    </ExempleComponentStyled>
  );
};

const ExempleComponentStyled = styled.div`
  .mobile {
    p {
      margin-bottom : ${ size0 };
    }
  }

  .textStart {
    color : ${ offerColor };
  }
`;

export default ExempleComponent;

