import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { CarouselStyled } from '../../styles/carousel.styled';
import { desktopSize } from '../../styles/variables';
import { SectionStyle } from '../../styles/structure';
import { SectionTitle } from '../../styles/font';
import OurClientComponentDesktop from './our-client.component.desktop';
import OurClientComponentMobile from './our-client.component.mobile';

const OurClientContainer = () => {
  const isDesktopOrLaptop = useMediaQuery( {
    query : `(min-width: ${ desktopSize })`
  } );

  return (
    <OurClientContainerStyled id="ourClient">
      <SectionStyle>
        <CarouselStyled>
          <SectionTitle justifyCenter><h1>Ils nous font confiance</h1></SectionTitle>
          { isDesktopOrLaptop ?
            <OurClientComponentDesktop/> :
            <OurClientComponentMobile/>
          }
        </CarouselStyled>
      </SectionStyle>

    </OurClientContainerStyled>
  );
};

const OurClientContainerStyled = styled.div`

`;

export default OurClientContainer;
