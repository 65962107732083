import React from 'react';
import Swiper from 'react-id-swiper';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import agnes_jbeili from '../../resources/images/agnes_jbeili.jpeg';
import emmanuelle_pays from '../../resources/images/emmanuelle_pays.jpeg';
import eric_cros from '../../resources/images/eric_cros.jpeg';
import severine_lacan from '../../resources/images/severine_lacan.jpeg';
import sylvie_gauthier from '../../resources/images/sylvie_gauthier.jpeg';
import virginie_beziel from '../../resources/images/virginie_beziel.jpeg';
import flecheUp from '../../resources/svg/fleche_up.svg';
import { CarouselStyled } from '../../styles/carousel.styled';
import { SectionTitle } from '../../styles/font';
import { SectionStyle } from '../../styles/structure';
import { desktopSize, myMain } from '../../styles/variables';
import TestimonialItemsElement from './TestimonialItems.element';

const text = [
  {
    text       : 'Le cabinet Prairial a créé le programme de formation des Middle-Managers de Steria France. A chaque étape du projet, que ce soit la conception des modules ou l\'animation des différentes sessions, Prairial a fait preuve de professionnalisme, de proactivité et d\'un vrai sens du service. La compréhension de nos enjeux et problématiques opérationnelles a permis à Prairial de délivrer en temps et en heure un programme de grande qualité.',
    name       : 'Virginie BEZIEL',
    position   : 'Directrice formation',
    enterprise : 'Sopra Steria',
    image      : virginie_beziel
  }, {
    text       : 'Prairial a un réel talent pour concevoir des dispositifs de formation managériale pragmatiques et efficaces avec professionnalisme et soin pour ses clients.',
    name       : 'Sylvie Gauthier',
    position   : 'Responsable Gouvernement des Données et Conformité',
    enterprise : 'Saint-Gobain Bâtiment France',
    image      : sylvie_gauthier
  }, {
    text       : 'Prairial est un cabinet très engagé et impliqué dans l\'adaptation de ses interventions aux attentes de ses clients. Nous recommandons son expertise :)',
    name       : 'Séverine Lacan',
    position   : 'Présidente',
    enterprise : 'Activ\'Partners',
    image      : severine_lacan
  }, {
    text       : 'J\'ai eu la chance d\'être accompagnée par Prairial durant une période critique de mon parcours de Chef d\'Entreprisedans le numérique.J\'ai hautement apprécié la qualité du coaching qui m’a permis de voir lucidement les points critiques et axes d’amélioration professionnels avec une approche constructive et positive.',
    name       : 'Agnès Jbeily',
    position   : 'Key Account Manager',
    enterprise : 'Gartner Group',
    image      : agnes_jbeili
  }, {
    text       : 'J\'ai eu la chance de travailler avec Prairial pour la mise en place d\'une formation et d\'ateliers de codéveloppement pour les managers de notre entreprise. Prairial est intervenu nous dans un contexte de changement de direction et de restructurations. Prairial a su en toute simplicité intervenir et aider à fédérer les énergies autour des enjeux et objectifs avec humilité, fiabilité, efficacité et plaisir.',
    name       : 'Eric Cros',
    position   : 'Directeur de Programmes',
    enterprise : 'Talan Labs',
    image      : eric_cros
  }, {
    text       : 'J\'ai eu le plaisir de faire intervenir Prairial dans le cadre de notre projet de développement managérial.Le cabinet Prairial a conçu un dispositif innovant et ludique de grande qualité et totalement adapté à nos enjeux et besoins de nos managers. Le dispositif a été plébiscité. Prairial a su cerner notre culture d\'entreprise et intervenir avec rigueur, empathie et dynamisme.',
    name       : 'Emmanuelle Pays',
    position   : 'DRH',
    enterprise : 'Extia',
    image      : emmanuelle_pays
  }
];

const OurClientReturnContainer = ( { showTitle } ) => {
  const isDesktopOrLaptop = useMediaQuery( {
    query : `(min-width: ${ desktopSize })`
  } );

  //const defaultControlsConfig = {
  //  nextButtonText : ' ',
  //  prevButtonText : ' '
  //};

  const params = {
    pagination   : {
      el        : '.swiper-pagination',
      type      : 'bullets',
      clickable : true
    },
    navigation   : {
      nextEl : '.swiper-button-next',
      prevEl : '.swiper-button-prev'
    },
    noSwiping    : isDesktopOrLaptop,
    spaceBetween : 30
  };

  return (
    <OurClientContainerStyled id="ourReturnClient" className="center-slider">

      {
        showTitle ?
        <SectionTitle justifyCenter><h1>Nos retours clients</h1></SectionTitle>
        : null
      }

      <CarouselStyled>
        <Swiper { ...params }>
          {
            text.map( ( el, i ) =>
              <div key={ i }>
                <TestimonialItemsElement  { ...el as any } />
              </div>
            )
          }
        </Swiper>
      </CarouselStyled>

    </OurClientContainerStyled>
  );
};

const OurClientContainerStyled = styled( SectionStyle )`
  
  p {
    font-weight: bold;
  }
  // commun
  width     : 100%;
  margin    : auto;
  max-width : 1000px;

  .swiper-button-prev {
    transform : rotate(-90deg);
    left      : 0;
  }

  .swiper-button-next {
    transform : rotate(90deg);
    right     : 0;
  }

  .swiper-wrapper {
    align-items : center;
  }

  .swiper-pagination {
    bottom : 0;
  }

  .swiper-pagination-bullet-active {
    background : ${ myMain };
  }

  .swiper-button-next, .swiper-button-prev {
    background : url("${ flecheUp }") no-repeat center !important;
    text-align : center;
    display    : none;

    &:after {
      content : none;
    }
  }

  // bigPhone 
  @media (min-width : 400px) {
    width : 95%;

    .swiper-button-next, .swiper-button-prev {
      display : block;
      width   : 30px;
      height  : 40px;
    }
  }

  // ipad
  @media (min-width : 700px) {
    width : 95%;

    .swiper-button-next, .swiper-button-prev {
      display : block;
      width   : 40px;
      height  : 40px;
    }

  }


`;

export default OurClientReturnContainer;
