import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { SectionTitle } from '../../styles/font';
import { desktopSize } from '../../styles/variables';
import OurAimComponentDesktop from './our-aim.component.desktop';
import OurAimComponentMobile from './our-aim.component.mobile';

const OurAimContainer = () => {

  const isDesktopOrLaptop = useMediaQuery( {
    query : `(min-width: ${ desktopSize })`
  } );

  return (
    <div id="youAre" className="myContainer">
      <SectionTitle justifyCenter><h1>Vous Etes</h1></SectionTitle>
      { isDesktopOrLaptop ?
        <OurAimComponentDesktop/> :
        <OurAimComponentMobile/>
      }
    </div>
  );
};

export default OurAimContainer;
