import React, { FC } from 'react';
import img from 'resources/offer/petite_fleche_orange.svg';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { offerColor, size2 } from 'styles/variables';

interface Props {
  isMobile: boolean
}

const text1 = `Accueil petit déj., cadre, règles du jeu et Ice-breaker pour que le groupe se connaisse et se fasse confiance`;
const text2 = `Présentation du codéveloppement et sélection du sujet à traiter (qui sera « client »)`;
const text3 = `Animation de la séance de codéveloppement`;
const text4 = `Présentation des next steps possibles`;
const text5 = `Clôture et évaluation de la séance`;

const hour1 = '9h00';
const hour2 = '9h15';
const hour3 = '9h30';
const hour4 = '10h30';
const hour5 = '11h00';

const AgendaComponent: FC<Props> = ( { isMobile } ) => {
  return (
    <AgendaComponentStyled>
      <h2 className="offreTitle">AGENDA D’UNE SÉANCE TYPE :</h2>
      <div className="offerContainer">

        {
          isMobile ? (
            <div className="mobile">
              <img src={ img } width={ 80 } alt="fleche"/>
              <p>
                <span className="hour">{ hour1 }</span> - { text1 }
              </p>
              <p>
                <span className="hour">{ hour2 }</span> - { text2 }
              </p>
              <p>
                <span className="hour">{ hour3 }</span> - { text3 }
              </p>
              <p>
                <span className="hour">{ hour4 }</span> - { text4 }
              </p>
              <p>
                <span className="hour">{ hour5 }</span> - { text5 }
              </p>
            </div>
          ) : (
            <div className="desktop">
              <Flex className="group-item">
                <p className="hour">{ hour1 }</p>
                <p className="orange_arrow">{ text1 }</p>
              </Flex>
              <Flex className="group-item">
                <p className="hour">{ hour2 }</p>
                <p className="orange_arrow">{ text2 }</p>
              </Flex>
              <Flex className="group-item">
                <p className="hour">{ hour3 }</p>
                <p className="orange_arrow">{ text3 }</p>
              </Flex>
              <Flex className="group-item">
                <p className="hour">{ hour4 }</p>
                <p className="orange_arrow">{ text4 }</p>
              </Flex>
              <Flex className="group-item">
                <p className="hour">{ hour5 }</p>
                <p className="orange_arrow">{ text5 }</p>
              </Flex>
            </div>
          )
        }


      </div>

    </AgendaComponentStyled>
  );
};

const AgendaComponentStyled = styled.div`
  .desktop {
    .group-item {
       .hour {
        margin-right: 30px;
        color: ${ offerColor };
       }
    }
  }
  .mobile {
    img {
      display: block;
      margin: auto;
      margin-bottom: ${size2};
    }
  }
`;

export default AgendaComponent;

