import React, { FC } from 'react';
import styled from 'styled-components';
import { offerColor, size0 } from 'styles/variables';

interface Props {
  isMobile: boolean
}

const text = [
  {
    question : 'Suis-je obligé de réserver l’atelier à la fin de la séance d’initiation ?',
    answer   : 'Pas d’obligation de poursuivre après la séance découverte, cela va de soi.'
  }, {
    question : 'Quelle est la taille du groupe de l’atelier de codéveloppement professionnel ?',
    answer   : 'Un petit groupe de 6 à 8 participants pour un maximum d’efficacité, de convivialité et d’autonomie progressive.'
  }, {
    question : 'Comment les 4 dates de l’atelier sont définies ?',
    answer   : 'La première date est définie ensemble à l’avance puis nous définissons en fin de séance les dates des 4 RDV ainsi que\n' +
      'les sujets traités, lesclients/consultants etc...'
  }, {
    question : 'Quelle est la durée totale et le rythme des séances ?',
    answer   : 'Les séances sont bimensuelles sachant que le rythme est adapté en fonction de chaque groupe avec les participants et\n' +
      'l’animateur. Le groupe peut décider de rapprocher les séances ou les rassembler en une demi-journée par exemple.'
  }, {
    question : 'Que se passe-t-il si je manque une séance ?',
    answer   : 'Si vous manquez une séance, vous aurez accès à une séance découverte ultérieure si vous le souhaitez. En revanche, si\n' +
      'vous manquez plus d’une séance, il ne sera pas possible de les remplacer car c’est votre engagement qui fait la richesse\n' +
      'des apports.'
  }, {
    question : 'Quel est votre rôle en tant qu’animatrice ?',
    answer   : 'En tant qu’animatrice professionnelle de groupes de codéveloppement, je garantis le cadre du groupe (confidentialité,\n' +
      'bienveillance, engagement, équité de prise de parole). Je régule les échanges. J’aide les participants à devenir co-res-\n' +
      'ponsables et autonomes, je garantis un début et une fin de séance « on time ». Je challenge sur les règles d’efficacité\n' +
      '(ponctualité, disponibilité, préparation et suivi). Je m’assure que chacun est à l’aise et progresse à son rythme.\n' +
      'Ma priorité est que le groupe devienne mature et autonome dans le but de prendre en main son propre groupe s’il le\n' +
      'souhaite ou devenir un ambassadeur et relais du changement dans son organisation ou chez ses clients.'
  }
];

const FaqComponent: FC<Props> = ( { isMobile } ) => {
  return (
    <FaqComponentStyled>

      <h2 className="offreTitle">FAQ</h2>
      <div className="offerContainer">
        {
          text.map( ( el, index ) =>
            <div key={ index }>
              <p className="question">{ el.question }</p>
              <p>{ el.answer }</p>
            </div>
          )
        }
      </div>

    </FaqComponentStyled>
  );
};

const FaqComponentStyled = styled.div`
  .question {
    color: ${ offerColor };
  }
  
  p {
    margin-bottom: ${ size0 };
  }
`;

export default FaqComponent;

