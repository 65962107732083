import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import linkedIn from '../../resources/svg/picto_contact_linkedin.svg';
import bottomArrow from '../../resources/svg/picto_fleche_equipe.svg';
import { mainGradient } from '../../styles/variables';

const TeamTop = ( { name, skill, linkUrl } ) =>
  <Flex justifyAround alignCenter full className="teamTop">

    <FlexItem basis='30%' style={ { position : 'relative' } }>
      <Flex colunm justifyAround alignCenter>
        <p className="name">{ name }</p>
      </Flex>
      <a href={ linkUrl } className="linkedIn">
        <img src={ linkedIn } alt="link"/>
      </a>
    </FlexItem>

    <FlexItem basis="10%">
      <div className="bottomArrow">
        <img src={ bottomArrow } alt="bottom"/>
      </div>
    </FlexItem>

    <FlexItem basis="60%">
      <Flex full nowrap justifySpace alignCenter>
        <p className="skill">{ skill }</p>
      </Flex>
    </FlexItem>

  </Flex>;

const TeamBottom = ( { img, text, linkUrl } ) =>
  <Flex>

    <FlexItem basis="30%">

      <Flex full column justifyEnd>
        <img className="profilePic" src={ img } alt="profile"/>
      </Flex>
    </FlexItem>

    <FlexItem basis="10%"/>

    <FlexItem basis="60%">
      {
        text.map( ( el, index ) => <p key={ index }>{ el }</p> )
      }

    </FlexItem>

  </Flex>;

const TeamElementPage = ( props ) =>
  <TeamElementPageStyled>
    <div className="teamContainer">
      <div className="teamContent">
        <TeamTop { ...props }/>
        <TeamBottom { ...props }/>
      </div>
    </div>
  </TeamElementPageStyled>;

const TeamElementPageStyled = styled.div`
  p {
    font-weight : bold;
    font-size: 18px;
  }
  
  

  .teamContainer {
    background : ${ mainGradient };
    max-width      : 900px;
    margin     : 24px auto;
    padding    : 4px;
  }

  .teamContent {
    background : white;
    padding    : 24px;
  }

  .teamTop {
    margin-bottom : 24px;
  }

  .left {
    margin-right : 32px;
  }

  .name {
    font-weight   : bold;
    font-size     : 24px;
    text-align    : center;
    line-height   : 24px;
    margin-bottom : 0px;
    white-space   : pre;
  }

  .skill {
    font-size     : 20px;
    font-weight   : bold;
    margin-bottom : 0;
  }

  .bottomArrow {
    width : 80px;

    img {
      width     : 40px;
      transform : rotate(-90deg);
    }
  }

  .linkedIn {
    position      : absolute;
    bottom        : -50px;
    left          : 50%;
    transform     : translateX(-50%);
    margin-bottom : -24px;

    img {
      width  : 40px;
      margin : 16px auto;
    }
  }

  .profilePic {
    width : 100%
  }


`;

export default TeamElementPage;
