import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import {
  font3,
  font4,
  font5,
  offerColor,
  offerGradient,
  size0,
  size1,
  size2,
  size3,
  size4,
  size5
} from 'styles/variables';
import arrowPeople from '../../resources/offer/bonhomme_titre.svg';
import notPretty from '../../resources/offer/labirynthe.svg';
import logo from '../../resources/svg/logo.png';

interface Props {
  isMobile: boolean;
}

const IntroTop = ( { isMobile } ) => (
  <IntroTopStyled isMobile={ isMobile }>
    <Flex column={ isMobile } justifyCenter alignCenter>
      <FlexItem className="bonhomme">
        <img width={ isMobile ? 150 : 200 } src={ arrowPeople } alt="bonhome"/>
      </FlexItem>
      <FlexItem>
        <h2>ATELIER CO-DEVELOPPEMENT</h2>
        <h3>Pour résoudre <br/>vos défis professionnels <br/> de manière innovante</h3>
      </FlexItem>
    </Flex>
  </IntroTopStyled>
);

const IntroTopStyled = styled.div<{ isMobile: Boolean }>`
  .bonhomme {
    margin-right : ${ ( { isMobile } ) => isMobile ? 0 : size5 };
  }
  
  h2 {
    text-align: center;
    font-weight: bold;
    margin-top: ${ size2 };
  }

  h3 {
    margin-top  : ${ size3 };
    text-align  : center;
    transform   : rotate(-3deg);
    background  : ${ offerGradient };
    color       : #fff;
    font-weight : bold;
    padding     : 4px 8px 14px;

  }`;

const firstParagraph = `Vous avez un défi à relever dans le cadre de votre activité professionnelle, dans un contexte complexe de transformation, de changement culturel ou structurel, d’innovation ?`;
const secondParagraph = `Vous avez un projet professionnel à mener ou une difficulté d’ordre stratégique, relationnelle ou managériale ?`;

const IntroBottom = ( { isMobile } ) => (
  <IntroBottomStyled>

    { isMobile ?
      (
        <Flex column alignCenter className="introMobile">
          <p> { firstParagraph }</p>
          <img width={ isMobile ? 150 : 150 } src={ notPretty } alt="labirynthe"/>
          <p>{ secondParagraph }</p>
        </Flex>
      )
               :
      ( <Flex className="introDesktop">
        <FlexItem basis="75%">
          <p className="orange_arrow"> { firstParagraph }</p>
          <p className="orange_arrow">{ secondParagraph }</p>
        </FlexItem>
        <FlexItem basis="20%" className="labi">
          <img width={ isMobile ? 150 : 150 } src={ notPretty } alt="labirynthe"/>
        </FlexItem>
      </Flex> )
    }

  </IntroBottomStyled>
);

const IntroBottomStyled = styled.div`
  margin-top : ${ size3 };
  
  .labi {
    img { 
      display: block;
      margin: auto;
    }
  }

  p {
    font-size : ${ font4 };
    margin    : ${ size0 } 0;
  }

  .introMobile {
    text-align : center;

    img {
      margin : ${ size2 } 0;
    }
  }
`;

const OfferIntroComponent: FC<Props> = ({isMobile}) => {

  return (
    <OfferIntroComponentStyled className="offerContainer" isMobile={ isMobile }>
      <IntroTop isMobile={ isMobile }/>
      <IntroBottom isMobile={ isMobile }/>

      <Flex column alignCenter>
        <h3 className="orangeTitle">L’ATELIER CODÉVELOPPEMENT EST FAIT POUR VOUS !</h3>
        <img className="logoPrairial" width={ isMobile ? 250 : 300 } src={ logo } alt="logo"/>

        <h5 className="conclusion">Prairial, cabinet spécialisé dans l’innovation managériale depuis plus de 10 ans, organise et anime pour vous des ateliers de co-développement.</h5>
      </Flex>

    </OfferIntroComponentStyled>
  );
};

const OfferIntroComponentStyled = styled.div<{ isMobile: Boolean }>`
  margin-top : ${ size4 };
 
  .conclusion {
   font-size: ${font5};
   font-weight: bold;
   text-align: center;
  } 
  
  .logoPrairial {
    transform: rotate(-4deg);
    margin-bottom: ${ size1 };
  }

  .orangeTitle {
    margin-top  : ${ size3 };
    color       : ${ offerColor };
    font-weight : 600;
    text-align  : center;
  }
`;

export default OfferIntroComponent;

