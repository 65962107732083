import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import mailIcon from '../resources/icon/mailIcon.svg';
import phoneIcon from '../resources/icon/phoneIcon.svg';
import background from '../resources/svg/callToActionBackground.svg';
import { size1, size3 } from '../styles/variables';

interface Props {
  interaction: string
  text: string
  img: string
}

const ShowModal = ( { isOpen, changeModal } ) =>
  <Modal size="sm" show={ isOpen }
         onHide={ () => changeModal( false ) }
         centered>
    <Modal.Header closeButton>
      Une fenêtres vient de s'ouvrir dans votre éditeur de mail pour nous contacter :).
    </Modal.Header>
  </Modal>;

export const mobile = {
  img         : phoneIcon,
  interaction : 'tel:0647813873',
  text        : 'Appeler',
  isDesktop   : false
};

export const desktop = {
  img         : mailIcon,
  interaction : `mailto:jenny.augias@prairial.com?subject=Demande d'info au cabinet Prairial&body=
  
  `,
  text        : 'Contacter',
  isDesktop   : true
};

const CallToActionComponent = ( { interaction, text, img, isDesktop } ) => {
  const isDesktopOrLaptop = useMediaQuery( { minWidth : 800 } );

  const [ isOpen, changeModal ] = useState( false );

  function showModal() {
    if ( isDesktop && isDesktopOrLaptop ) {
      changeModal( true );
    }
  }

  return (
    <CallToActionComponentStyled>
      <ShowModal isOpen={ isOpen } changeModal={ changeModal }/>
      <a onClick={ showModal } href={ interaction }>
        <Flex alignCenter justifyCenter>
          <img src={ img } alt=" "/>
          <p>
            { text }
          </p>
        </Flex>
      </a>
    </CallToActionComponentStyled>
  );
};

const CallToActionComponentStyled = styled.button`
  text-decoration : none !important;
  color           : inherit;
  background      : url("${ background }") no-repeat;
  background-size : cover;
  border          : none;
  margin          : 0;
  padding         : 0;
  border-radius: 4px;
  width: 160px;
  height: 40px;

  a {
    display : block;
    //padding : ${ size1 } ${ size3 };
  }

  img {
    width        : 20px;
    margin-right : 8px;
  }

  p {
    margin : 0;
    font-weight: bold;
  }
`;
const Call2Action = () => {
  const isMobile = useMediaQuery( { minWidth : 450 } );

  return (
    <Call2ActionStyled justifyCenter alignCenter>
      {
        !isMobile ? (
          <>
            <FlexItem className="item">
              <CallToActionComponent { ...mobile }/>
            </FlexItem>
            <FlexItem basis="5%"/>
          </>
        ) : null
      }
      <FlexItem className="item">
        <CallToActionComponent { ...desktop }/>
      </FlexItem>
    </Call2ActionStyled>
  );
};

const Call2ActionStyled = styled( Flex )`
  .item {
    //margin-bottom: 40px;
  }

`;

export default Call2Action;
