import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { startUpCarousel } from '../../setup';
import { mainColor } from '../../styles/variables';
import { ourValueOfferData } from './our-value-offer.data';
import OurValueOfferModalComponent from './our-value-offer.modal.component';

const BoxContainer = styled.div`
  background: white;
  padding: 20px;
  text-align: center;
  position: relative;
  height: 260px;
  
`;

const ButtonAbsolute = styled.button`
  position: absolute;
  right: 20px;
  bottom: 20px;
  border: none;
`;

const BoxElement = ( { el } ) => {
  const [ isOpen, changeModal ] = useState( false );

  return (
    <BoxElementStyled>
      <OurValueOfferModalComponent { ...{
        changeModal,
        isOpen, ...el
      } }/>
      <BoxContainer>
        <h4>{ el.title }</h4>
        <h5 className={ 'titleMinus ' + el.textColor }>{ el.titleMinus }</h5>
        <Flex justifyCenter>
          <img height={ 60 } src={ el.number } alt=""/>

          {
            el.button ? <Link to="/offre">
                        <ButtonAbsolute className={ el.className }>
                          Voir l'offre
                      </ButtonAbsolute></Link> :

            <ButtonAbsolute className={ el.className }
                            onClick={ () => changeModal( true ) }>
              en savoir +
            </ButtonAbsolute>
          }

        </Flex>
      </BoxContainer>
    </BoxElementStyled>

  );
};

const BoxElementStyled = styled.div`
  h4 {
    white-space: pre;
  }
  h5 {
    color: ${ mainColor }; 
  }
`;

const settings: Settings = {
  dots           : true,
  infinite       : true,
  speed          : 600,
  autoplay       : startUpCarousel,
  autoplaySpeed  : 10000,
  slidesToShow   : 1,
  slidesToScroll : 1,
  arrows         : false
};

const OurValueOfferComponentMobile = () =>
  <OurValueOfferComponentMobileStyled>
    <Slider { ...settings }>
      { ourValueOfferData.map(
        ( el, i ) => <BoxElement key={ i } el={ el }/> ) }
    </Slider>
  </OurValueOfferComponentMobileStyled>;

export const OurValueOfferComponentMobileStyled = styled.div`
`;
export default OurValueOfferComponentMobile;
