import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { darkColor, desktopSize, font7 } from './variables';

export const SectionTitle = styled( Flex )`

  @media ( max-width : 700px) {
    margin-bottom : 40px;
  }

  margin-bottom : 24px;

  h1 {
    font-family : "Bellota", cursive;
    display     : inline;
    margin      : 0;
    font-size   : ${ font7 };
    font-weight : 800;
    position    : relative;
    text-align  : center;
    text-transform: capitalize;
    //text-transform: uppercase;

    @media ( min-width : ${ desktopSize }) {
      margin-bottom : 16px;
    }

    &:after {
      position   : absolute;
      content    : "";
      height     : 3px;
      bottom     : -10px;
      margin     : 0 auto;
      left       : 0;
      right      : 0;
      width      : 75%;
      background : ${ darkColor };
    }
  }

`;

export const Title = styled.h1`
  margin-bottom: 20px;
`;
