import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { font2, mainColor } from "../../styles/variables";

const DivStyled = styled.div`
	img {
		display: block;
		margin: 20px auto;
	}
	p {
		font-size: 16px;
		font-weight: bold;
	}
`;


const OurValueOfferModalComponent = ( { isOpen, changeModal, titleModal, imgModal, textModal, sizeImg, titleMinusModal, textColor } ) =>
		<Modal size="sm" show={ isOpen }
					 onHide={ () => changeModal( false ) }
					 centered>
			<Modal.Header closeButton>
				<ModalStyled>
					<h5>
						{ titleModal }
					</h5>
					<h4 className={ "" + textColor }>
						{ titleMinusModal }
					</h4>
				</ModalStyled>
			</Modal.Header>
			<Modal.Body>
				<DivStyled>
					<img width={ sizeImg } src={ imgModal } alt="img"/>
					{ textModal.map( ( el, i ) => <p key={ i }>{ el }</p> ) }
				</DivStyled>
			</Modal.Body>
		</Modal>;

export const ModalStyled = styled.div`
 h4 {
    color: ${ mainColor }; 
    font-size: ${ font2 };
  }
   .text--yellow {
   color: #FDA085;
  }
    .text--red {
    color: #FA5861;
  }

p{
font-size: ${ font2 };
}

`;

export default OurValueOfferModalComponent;
