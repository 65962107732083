import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { teamData } from '../../containers/team-component/team-other.component';
import { SectionTitle } from '../../styles/font';
import { mainGradient } from '../../styles/variables';
import TeamElementPage from './teamElementPage';

const TeamPage = ( { history } ) =>
  <TeamPageStyled>

    <SectionTitle justifyCenter><h1 className="titleMarginDesktop">L'Equipe</h1>
    </SectionTitle>
    {
      teamData.map( ( el, i ) =>
        <TeamElementPage key={ i } { ...el }/> )
    }

    <div className="return">
      <a href="/#team" >Retour</a>
    </div>
  </TeamPageStyled>;

const TeamPageStyled = styled.div`
  //background: ${ mainGradient };
  padding: 32px;
  .return {
    background : ${ mainGradient };
    padding: 3px;
    width: 140px;
    a {
      display: block;
      margin: auto;
      background: white;
      text-align: center;
    font-weight: bold; 
    font-size: 24px;
    }
    margin: 50px auto;
  }
`;

export default withRouter( TeamPage );
