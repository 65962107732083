import AgendaComponent from 'pages/offer/agenda.component';
import ClientComponent from 'pages/offer/client.component';
import CodevComponent from 'pages/offer/codev.component';
import ExempleComponent from 'pages/offer/exemple.component';
import FacilitatriceComponent from 'pages/offer/facilitatrice.component';
import FaqComponent from 'pages/offer/faq.component';
import ModaliteComponent from 'pages/offer/modalite.component';
import OfferIntroComponent from 'pages/offer/offerIntro.component';
import WhoCodevComponent from 'pages/offer/whoCodev.component';
import WhyComponent from 'pages/offer/why.component';
import React, { FC, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { font4, font5, mainGradient, offerGradient, size3, size4 } from 'styles/variables';
import arrow from '../../resources/offer/petite_fleche_orange.svg';

interface Props {

}

const OfferPage: FC<Props> = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isMobile = useMediaQuery( { maxWidth : 800 } );
  return (
    <OfferPageStyled isMobile={ isMobile }>

      <OfferIntroComponent isMobile={ isMobile }/>
      <CodevComponent isMobile={ isMobile }/>
      <WhoCodevComponent isMobile={ isMobile }/>
      <WhyComponent isMobile={ isMobile }/>
      <FacilitatriceComponent isMobile={ isMobile }/>
      <ModaliteComponent isMobile={ isMobile }/>
      <AgendaComponent isMobile={ isMobile }/>
      <ExempleComponent isMobile={ isMobile }/>
      <ClientComponent/>
      <FaqComponent isMobile={ isMobile }/>
      <Footer>
        <div className="footer">
          <p>Tous droits réservés © 2020 Prairial </p>
          <p>Web design création : nfgraphisme@gmail.com</p>
          <div className="return">
            <p>
              <a href="/legal"> Mentions légales</a>
            </p>
          </div>
        </div>
      </Footer>

    </OfferPageStyled>
  );
};

const OfferPageStyled = styled.div<{ isMobile: boolean }>`

  font-weight : bold;
 
  p {
  font-size   : 18px;
  }

  .offreTitle {
    margin      : ${ size4 } 0;
    background  : ${ offerGradient };
    padding     : ${ size3 };
    text-align  : center;
    font-weight : 700;
    color       : black;
    font-size: ${ ( { isMobile } ) => isMobile ? font4 : font5 };
  }

  .offerContainer {
    max-width : 1000px;
    margin    : auto;
    padding     : ${ size3 };
  }

  .orange_arrow {
    position : relative;
    margin-left: 20px;

    &:before {
      content           : '';
      background-image  : url("${ arrow }");
      background-repeat : no-repeat;
      width             : 20px;
      height            : 20px;
      position          : absolute;
      left              : -20px;
      top               : 6px;
      transform         : rotate(-90deg);
    }
  }

`;

const Footer = styled.div`


   .return {
    background : ${ offerGradient };
    margin: 20px auto;
    padding: 2px 0;
    width: 220px;
    a {
      display: block;
      margin: auto;
      background: white;
      text-align: center;
    font-weight: bold; 
    //font-size: 24px;
    }}

  margin: 20px 0;
  p {
    margin: 0;
    font-weight: bold;
    text-align: center;
  }
`;

export default OfferPage;
