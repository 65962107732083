import React, { Fragment, useState } from 'react';
import CountUp from 'react-countup';
import { useMediaQuery } from 'react-responsive';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import cocard from '../../resources/svg/picto_cocarde_certification_continuelle.svg';
import arrow from '../../resources/svg/picto_fleche_differenciant.svg';
import maitrise from '../../resources/svg/picto_maitrisecomplete_differenciant.svg';
import eye from '../../resources/svg/picto_oeil_veille_continuelle.svg';
import formation from '../../resources/svg/picto_ordi_formation_continuelle.svg';
import { desktopSize, font2, font3, font5 } from '../../styles/variables';
import { SkillsSlider } from './our-skills.slider.component';

const someNumberData = [ {
  text        : <>Spécialisé dans l’innovation <br/>managériale depuis</>,
  nb          : 10,
  nbText      : 'ans',
  textNewLine : ''
}, {
  text        : <>A déjà accompagné <br/> vers le succès</>,
  nb          : 500,
  nbText      : '',
  textNewLine : '\nmanagers'
}, {
  text        : <>C’est réseau d’experts <br/>indépendants intervenant dans</>,
  nb          : 10,
  nbText      : 'pays',
  textNewLine : '\n& FRANCE'
} ];

export function NumberPart() {
  const [ visible, setVisible ] = useState( false );
  const setIcon2lines = useMediaQuery( { minWidth : 450 } );
  const setIcon4lines = useMediaQuery( { minWidth : 900 } );

  let iconWidth = '100%';
  iconWidth = setIcon2lines ? '50%' : iconWidth;
  iconWidth = setIcon4lines ? '30%' : iconWidth;

  return (
    <Waypoint onEnter={ () => setVisible( true ) }>
      {
        visible ?
        <NumberPartStyled justifyAround alignTop className="element-container" wrap="true">
          {
            someNumberData.map(
              ( { text, nb, nbText, textNewLine }, index ) =>
                <FlexItem key={ index } basis={ iconWidth } className="number-item">
                  <p>{ text }</p>
                  <CountUp end={ nb } start={ 0 } delay={ 0 }>
                    {
                      ( { countUpRef } ) =>
                        <p className="number"> +&nbsp;
                          <span className="number" ref={ countUpRef }/>
                          <span className="number">&nbsp;{ nbText }</span>
                          <span className=" number textNewLine"> { textNewLine }</span>
                        </p>
                    }
                  </CountUp>
                </FlexItem>
            )
          }
        </NumberPartStyled> : null
      }
    </Waypoint>
  );
}

const NumberPartStyled = styled( Flex )`
p {
  font-size: ${ font2 };
  font-weight: bold;
}
 .number-item {
    .number {
    font-size: 30px;
    @media (min-width: 800px) {
      font-size: 40px;
    }
      line-height: 28px;
      font-weight: bold;
      color: white;
    }

    .textNewLine {
    font-size: 20px;
    @media (min-width: 800px) {
      font-size: 30px;
    }
      white-space: pre;
    }

  }
`;

const pictoData = [
  { type : 'picto', text : <>Formation <br/> Continuelle</>, img : formation },
  { type : '+', text : '+' },
  { type : 'picto', text : <>Veille <br/> Continuelle</>, img : eye },
  { type : '+', text : '+' },
  { type : 'picto', text : <>Certification <br/> Continuelle</>, img : cocard },
  { type : 'picto', text : '', img : arrow },
  {
    type : 'picto',
    text : 'Maîtrise des Dernières Innovations',
    img  : maitrise
  }
];

const Picto = () =>
  <div className="element-container picto">
    <Flex jutifyBetween full>
      {
        pictoData.map( ( el, index ) =>

          (
            <FlexItem basis={ el.type === 'picto' ? '20%' : '10%' } key={ index }>
              <Flex full column justifyCenter alignCenter>
                {
                  el.type === 'picto' ?
                  <Fragment>
                    <img width={ 80 } src={ el.img } alt="img"/>
                    <p>{ el.text }</p>
                  </Fragment>
                                     :
                  <p className="bigPlus">+</p>
                }
              </Flex>
            </FlexItem>
          )
        )
      }
    </Flex>
  </div>;

const OurSkillsComponentDesktop = () => {
  const set3Slider = useMediaQuery( { minWidth : 1200 } );

  let sliderNb = set3Slider ? 3 : 2;

  return (
    <OurSkillsComponentDesktopStyled>
      <div className="myContainer">
        <NumberPart/>

        <p className="subTitle" style={ { margin : '16px 0 0' } }>Prairial est une organisation agile et apprenante</p>
        <Picto/>

        <h4 className="subTitle">Une équipe d’experts indépendants intervenant en France et à l’étranger</h4>
        <p className="text-intro">
          Chaque membre de l'équipe prairial a un double profil avec une expérience significative en entreprise et des expertises complémentaires comme :</p>

      </div>

      <div className="skill-slider">
        <SkillsSlider nbSlide={ sliderNb }/>
      </div>

    </OurSkillsComponentDesktopStyled>
  );
};

const OurSkillsComponentDesktopStyled = styled.div`

  .subTitle {
    text-align: left;
    font-family: "Bellota", cursive;
    font-weight: bold;
    font-size: ${ font5 };
  }
 
  p {
    font-weight: bold;
    font-size: ${ font3 };
  }
  text-align: center;
  
  .bigPlus {
    line-height: 40px;
    height: 88px;
    font-size: 80px;
    color: white;
    font-weight: bolder;
    margin: 0 !important;
  }


  h3 {
    text-align: left;
    font-size: 20px;
    font-weight: bold;
  }

  .title-margin {
    margin-top: 24px;
  }

  .text-intro {
    text-align: left;
    margin: 0;
  }

 

  .picto {
    p {
      margin-top: 16px;
    }
  }

  .skill-slider {
    margin-top: 16px;
    @media ( min-width: ${ desktopSize }) {
      font-weight: bold;
    }

  }

`;

export default OurSkillsComponentDesktop;
