import React, { Fragment } from 'react';
import HeroComponent from '../containers/hero/heroComponent';
import OurMissionContainer
  from '../containers/our-mission/our-mission.container';
import OurValueOfferContainer
  from '../containers/our-value-offer/our-value-offer.container';
import OurAimContainer from '../containers/our-aim/our-aim.container';
import OurClientContainer from '../containers/our-client/our-client.container';
import OurSkillsContainer from '../containers/our-skills/our-skills.container';
import OurClientReturnContainer
  from '../containers/our-client-return/our-client-return.container';
import VisionComponent from '../containers/our-vision/vision.component';
import TeamContainer from '../containers/team-component/team.container';
import ContactContainer from '../containers/contact/contact.container';
import WeDoContainer from '../containers/WeDo.container';

const MainPage = () => <Fragment>
  <HeroComponent/>
  <VisionComponent/>
  <OurAimContainer/>
  <WeDoContainer/>
  <OurMissionContainer/>
  <OurValueOfferContainer/>
  <OurClientContainer/>
  <OurSkillsContainer/>
  <OurClientReturnContainer showTitle={true}/>
  <TeamContainer/>
  <ContactContainer/>
</Fragment>;

export default MainPage;
