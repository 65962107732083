import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { SectionTitle } from '../../styles/font';
import { SectionStyle } from '../../styles/structure';
import { font2, mainGradient, size2 } from '../../styles/variables';
import OurValueOfferComponentDesktop from './our-value-offer-component.desktop';
import OurValueOfferComponentMobile from './our-value-offer.component.mobile';

const OurValueOfferContainer = () => {

  const isDesktopOrLaptop = useMediaQuery( {
   minWidth : 900
  } );

  return (
    <SectionStyleBackground id="approche">
      <div className="myContainer">

        <SectionTitle justifyCenter>
          <h1 className="titleMarginDesktop">Notre Approche</h1>
        </SectionTitle>
        <div className="introText">
          <p>
            A partir de vos ressources managériales internes, nous allons vous permettre de changer l’état d’esprit des équipes en expérimentant de nouveaux modes de travail vers une culture de travail agile et source d'innovation. <br/>
          </p>
          <p> La méthodologie Prairial vous aide à co-construire, tester puis mettre en application les innovations managériales qui vous correspondent. <br/>
          </p>
          <p> Nos offres reposent sur 3 piliers et une approche sur-mesure afin de garantir les meilleurs résultats: </p>
        </div>

        { isDesktopOrLaptop ?
          <OurValueOfferComponentDesktop/> :
          <OurValueOfferComponentMobile/> }
      </div>
    </SectionStyleBackground> );
};

const SectionStyleBackground = styled( SectionStyle )`
  background : ${ mainGradient };


  .introText {
    p {
      text-align  : center;
      font-weight : bold;
      //font-size   : ${ size2 };

    }

    margin-bottom : 32px;
  }


  .button--yellow {
    font-size   : ${ font2 };
    font-weight : bolder;
    background  : linear-gradient(90deg, #FCE55F 0%, #FDA085 65%);
  }
  
  .text--yellow {
   color: #FDA085;
  }

  .button--red {
    font-size   : ${ font2 };
    font-weight : bolder;
    background  : linear-gradient(90deg, #FF9A9E 0%, #FA5861 65%);
  }
  
  .text--red {
    color: #FA5861;
  }

  .button--blue {
    font-size   : ${ font2 };
    font-weight : bolder;
    background  : ${ mainGradient };
  }
`;

export default OurValueOfferContainer;
