import React from 'react';
import styled from 'styled-components';
import logo from '../../resources/svg/logo.png';
import { desktopSize } from '../../styles/variables';

const LogoComponent = () =>
  <LogoComponentStyled>
    <div className="logo">
      <img src={ logo } alt="logo"/>
    </div>
  </LogoComponentStyled>;

const LogoComponentStyled = styled.div`
  .logo {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    z-index: 100;

    img {
      display: block;
      margin: auto;
      padding-left: 20px;
      width: 55%;
      max-width: 250px;

      @media ( min-width: ${ desktopSize }) {
        width: 250px;
      }
    }
  `;

export default LogoComponent;
