import React, { FC } from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { font1, font2, font4, myMain, size0, size1, size2, size3, size4 } from '../../styles/variables';

interface Props {
  text: string;
  name: string;
  enterprise: string;
  position: string;
  image: string;
}

const TestimonialItemsElement: FC<Props> = ( { text, enterprise, name, position, image } ) => {
  return (
    <TestimonialItemsElementStyled column justifyCenter alignCenter>
      <div className="testimonial__card">
        <img className="testimonial__img" src={ image } alt="profile mage"/>
        <p className="testimonial__text">"{ text }"</p>
        <p className="testimonial__name">{ name }</p>
        <p className="testimonial__position">{ position }</p>
        <p className="testimonial__enterprise">{ enterprise }</p>
      </div>

    </TestimonialItemsElementStyled>
  );
};

const TestimonialItemsElementStyled = styled( Flex )`
  text-align : center;
  height     : 100%;
  padding-top: ${ size1 };

  .testimonial {

    &__card {
      width         : 80%;
      max-width     : 800px;
      margin-bottom : ${ size4 };
    }

    &__img {
      border-radius : 50%;
      width         : 100px;
      margin-bottom : ${ size3 };
      box-shadow    : 0 10px 25px 0 rgba(0, 0, 0, .3);
    }

    &__text {
      font-size     : ${ font2 };
      //font-weight   : ;
      opacity       : 0.75;
      margin-bottom : ${ size2 };
    }

    &__name {
      font-weight    : bold;
      letter-spacing : 0.1px;
      font-size      : ${ font4 };
      margin-bottom  : ${ size0 };
      margin-bottom  : 0;
    }

    &__position {
      font-weight: ${ font1 };
      text-transform : uppercase;
      letter-spacing : 0.8px;
      opacity        : 0.8;
      margin-bottom  : 0;
    }

    &__enterprise {
      color         : ${ myMain };
      font-size     : ${ font2 };
      margin-bottom : 0;
    }
  }
`;

export default TestimonialItemsElement;

